import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import Loader from "../Loader";

const RollzMix = () => {
  const [show, setShow] = useState(false);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    fetch();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);


  const fetch = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("customer_id", localStorage.getItem("vendor_id"));
    try {
      const response = await axios({
        method: "post",
        url: "https://api.rollzfranchise.com/api/customer_vendor_order",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setData(response.data.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [view_detail, setViewDetail] = useState([]);

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2" style={{ minHeight: "77vh" }}>
        <div className="container-box">
          <div className="top-heading">
            <h1>Vendor Orders</h1>
          </div>

          <div className="row mb-4 justify-content-center">
            <div className="col-12">
              <div className="white-box p-4 back-design">
                <div className="text-end">
                  <Link
                    to="/rollz-mix/add-vendor-order"
                    className="button btn-blue text-decoration-none"
                  >
                    <span className="fw-bold icon-center me-1">+</span> Add
                    Order
                  </Link>
                </div>

                <div className="table-responsive mt-4">
                  <table className="table table-bordered text-center mobile-table">
                    <thead className="table-danger">
                      <tr>
                        <th className="col-1">Order_Id</th>
                        <th className="col-1">Name</th>
                        <th className="col-1">Date</th>
                        <th className="col-2">Address</th>
                        <th className="col-1">Payment status</th>
                        <th className="col-1">Order status</th>
                        <th className="col-1">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.map((item, i) => {
                        let Status;
                        if (item.payment_status.toLowerCase() == "paid") {
                          Status = (
                            <span className="badge bg-success">Paid</span>
                          );
                        } else {
                          Status = (
                            <span className="badge bg-danger">Unpaid</span>
                          );
                        }

                        const onViewDetail = async (e) => {
                          setShow(true);
                          e.preventDefault();
                          setLoading(true);
                          try {
                            const response = await axios({
                              method: "get",
                              url: `https://api.rollzfranchise.com/api/vendor_order/${item.id}`,
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                              },
                            }).then((response) => {
                              setViewDetail(response.data.data);
                              setLoading(false);
                            });
                          } catch (err) {
                            swal({
                              icon: "error",
                              text: "SomeThing Went Wrong",
                            });
                            setLoading(false);
                          }
                        };

                        return (
                          <tr>
                            <td>#{item.id}</td>
                            <td>{item?.vendor_data.first_name + " " + item?.vendor_data.last_name}</td>
                            <td>
                              {moment(item.date_add).format("DD-MM-YYYY ")}
                            </td>
                            <td>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.vendor_data.address,
                                }}
                              ></span>
                            </td>
                            <td>{Status}</td>
                            <td>
                              <select
                                name=""
                                id=""
                                className="form-select rounded-0 py-1"
                                disabled
                              >
                                <option value="Select Status">
                                  {item.order_status}
                                </option>
                              </select>
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-secondary btn-sm"
                                onClick={onViewDetail}
                              >
                                <i className="bi bi-eye-fill"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal show={show} size="lg" centered>
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Vendor Order Details</Modal.Title>
          <div className="closeModalBtn" onClick={() => setShow(false)}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-bordered table-striped text-center">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>name</th>
                  <th>Quantity</th>
                </tr>
              </thead>

              <tbody>
                {view_detail.map((item, i) => {
                  return (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RollzMix;
