import "./App.css";
// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";

// franchise dashboard
import Login1 from "./components/LoginPage/Login1";
import ForgotPwd1 from "./components/LoginPage/ForgotPwd1";
import Dashboard from "./components/Home/Dashboard";
import OrderHistory from "./components/History/OrderHistory";
import Training from "./components/Training/Training";
import TrainingProgram from "./components/Training/TrainingProgram";
// ordering
import Ordering from "./components/Ordering/Ordering";
import AddOrder from "./components/Ordering/AddOrder";
// rollz mix
import RollzMix from "./components/RollzMix/RollzMix";
import AddVendorOrder from "./components/RollzMix/AddVendorOrder";

import EditProfile from "./components/Account/EditProfile";
import ChangePassword from "./components/Account/ChangePassword";
import ProtectedRoute from "./components/LoginPage/ProtectedRoutes";
import RedeemIndex from "./components/Home/Redeem";
import Credential from "./components/LoginPage/Credential";
import Offers from "./components/Offers/Offers";
import AddOffers from "./components/Offers/AddOffer";
import EditOffers from "./components/Offers/EditOffer";
import Logs from "./components/Account/Logs";
// ---------------------------------------------------------

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* franchies dashboard */}

          <Route path="/login" element={<Login1 />} />
          <Route path="/forget-password" element={<ForgotPwd1 />} />

          <Route path="/" element={<Dashboard />} />

          <Route path="/training" element={<Training />} />
          <Route path="/order-history" element={<OrderHistory />} />

          <Route path="/training-program" element={<TrainingProgram />} />

          <Route path="/ordering" element={<Ordering />} />

          <Route path="/ordering/add-order" element={<AddOrder />} />

          <Route path="/rollz-mix" element={<RollzMix />} />

          <Route path="/credential" element={<Credential />} />
          <Route path="/logs" element={<Logs />} />

          {/* offers */}
          <Route path="/offers" element={<Offers />} />
          <Route path="/add-offer" element={<AddOffers />} />
          <Route path="/edit-offer/:offer_id" element={<EditOffers />} />

          <Route
            path="/rollz-mix/add-vendor-order"
            element={<AddVendorOrder />}
          />

          {/* loyalty programme */}
          <Route path="/redeem/:id" element={<RedeemIndex />} />

          <Route path="/my-account" element={<EditProfile />} />

          <Route path="/change-password" element={<ChangePassword />} />

          {/* --------------------------------------------------------------------------------------- */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

const PrivateRoute = ({ isAuthenticated }) => {
  return isAuthenticated ? <Dashboard /> : <Navigate to="/login" />;
};

export default App;
