import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";

const NewPassword = ({ customer_name, customer_id, customer_pass }) => {
  const [reset_modal, setResetModal] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onResetPassword = async (e) => {
    e.preventDefault();
    if (confirm_password == password) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("password", password);
        formData.append("email", email);
        formData.append("user_id", localStorage.getItem("vendor_id"));
        const res = await axios.post(
          `${config.apiurl}reset_password_vendor`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setLoading(false);
        console.log(res);
        if (res.data.success) {
          setResetModal(false);
          swal({
            icon: "success",
            text: "Password Reset Successfully",
          }).then((data) => {
            localStorage.setItem("pass_reset", 1);
            localStorage.setItem("vendor_id", customer_id);
            localStorage.setItem("auth_name", customer_name);
            navigate("/");
          });
        } else {
          swal({
            icon: "warning",
            text: "Please Enter Correct Email Address",
          });
        }
      } catch (error) {
        console.log(error);
        swal("", "Something Went Wrong", "error");
        setLoading(false);
      }
    } else {
      swal({
        icon: "warning",
        text: "Please Enter Correct Password, Your New Password is Not Matching Confirm Password",
      });
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <Modal show={reset_modal} centered>
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Reset Your Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onResetPassword}>
            <div>
              <h5 style={{ color: "#eb3265", fontWeight: "560" }}>
                {" "}
                Create a new, secure password for your Account{" "}
              </h5>
            </div>
            <div className="row">
              <div className="col-12 mt-3">
                <label className="mb-1">Email Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email Address"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-12 mt-3">
                <label className="mb-1"> New Password</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter New Password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="col-12 mt-3">
                <label className="mb-1">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="col-12 mt-4 text-end">
                <button className="button btn-blue">Submit</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewPassword;
