import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import axios from "axios";
import Loader from "../Loader";

const TrainingProgram = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  const [isLoading, setLoading] = useState(true);
  const [data, setdata] = useState([]);
  const [training, setTraining] = useState([]);

  useEffect(() => {
    fetch();
    fetch2();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: "https://api.rollzfranchise.com/api/training_category",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setdata(response.data.data);
        const datas = response.data.data.slice(0, 1).map((items) => {
          setCategory(items.id);
        });
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const fetch2 = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: "https://api.rollzfranchise.com/api/training",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setTraining(response.data.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [category_id, setCategory] = useState("");

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2" style={{ minHeight: "77vh" }}>
        <div className="container-box">
          <div className="top-heading">
            <h1>Training Program</h1>
          </div>

          <div className="row mb-4 justify-content-center training-program">
            <div className="col-12">
              <div className="white-box py-4">
                <div className="row align-items-start py-2 ps-4">
                  <div
                    className="col-md-3 p-4 pb-2 nav nav-pills bg-light "
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {data.map((categ, i) => {
                      return (
                        <button
                          className="nav-link mb-3 w-100 text-start active"
                          id={`v-pills-${categ.id}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#v-pills-${categ.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`v-pills-${categ.id}`}
                          aria-selected="true"
                          key={i}
                          onClick={() => setCategory(categ.id)}
                        >
                          {categ.name}
                        </button>
                      );
                    })}
                  </div>

                  <div
                    className="col-md-9 tab-content px-4"
                    id="v-pills-tabContent"
                  >
                    {training.map((train, index) => {
                      if (train.manual_category_id == category_id) {
                        // console.log(train.manual_category_id == category_id)
                        const data = training.map((item) => {
                          if (item.manual_category_id == category_id) {
                            return item.id;
                          }
                        });

                        return (
                          <div
                            className="tab-pane fade show active"
                            tabindex="0"
                          >
                            <div
                              class="accordion accordion-splush"
                              id="accordion1"
                            >
                              <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header pb-0">
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#flush-collapse${
                                      index + 1
                                    }`}
                                    aria-expanded="false"
                                    aria-controls={`flush-collapse${index + 1}`}
                                  >
                                    {train.manual_title}
                                  </button>
                                </h2>
                                <div
                                  id={`flush-collapse${index + 1}`}
                                  className={
                                    data.filter((map) => map != undefined)[0] ==
                                    train.id
                                      ? "accordion-collapse collapse show"
                                      : "accordion-collapse collapse"
                                  }
                                  data-bs-parent="#accordion1"
                                >
                                  <div className="accordion-body">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          train.manual_description != 'null'
                                            ? train.manual_description
                                            : "-",
                                      }}
                                    ></span>
                                    <div className="mt-4">
                                      {train.document_type == "photo" ? (
                                        <a
                                          className="button btn-blue"
                                          href={`https://api.rollzfranchise.com/application/storage/app/public/${train.photo}`}
                                          download
                                          style={{ textDecoration: "none" }}
                                        >
                                          <i className="bi bi-download me-2"></i>
                                          Download Pdf
                                        </a>
                                      ) : (
                                        <a
                                          style={{ textDecoration: "none" }}
                                          target="_blank"
                                          href={train.photo}
                                          className="button btn-pink "
                                        >
                                          <i className="bi bi-eye-fill me-1"></i>
                                          View
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TrainingProgram;
