import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const PayPal = ({
  grand_total,
  subtotal,
  shipping,
  shipping_charges,
  handling_charges,
  total_tax,
  payMethod,
  PaypalCharges,
}) => {
  const paypalRef = useRef();
  const [show, setShow] = useState(true);

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Your description",
                amount: {
                  currency_code: "CAD",
                  value: parseFloat(grand_total).toFixed(2),
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          if (order.status == "COMPLETED") {
            swal({
              text: "Payment Done Successfully",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (data) => {
              setLoading(true);
              const formData = new FormData();
              formData.append("order_id", localStorage.getItem("order_id"));
              formData.append("payment_status", "paid");
              formData.append(
                "txn_id",
                order.purchase_units[0].payments.captures[0].id
              );
              formData.append("paypal_date", order.create_time);
              formData.append("paypal_status", order.status);
              formData.append("reason", "Paid");
              try {
                const response = await axios({
                  method: "post",
                  url: "https://api.rollzfranchise.com/api/payment_status_change",
                  data: formData,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                }).then((response) => {
                  localStorage.removeItem("order_id");
                  navigate("/ordering");
                  setLoading(false);
                });
              } catch (err) {
                swal({
                  icon: "error",
                  text: "SomeThing Went Wrong",
                });
                setLoading(false);
              }
            });
          }
        },
        onError: (err) => {
          console.error(err);
        },
      })
      .render(paypalRef.current);
  }, []);

  const [interact, setInteract] = useState(false);
  const [file, setFile] = useState("");

  const [interac_total, setInteracTotal] = useState("");
  const onChangePayment = () => {
    swal({
      text: "Please upload transaction receipt and etranster to franchise@rollzicecream.ca",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setInteract(true);
        setShow(false);
        setInteracTotal(parseFloat(grand_total) - parseFloat(PaypalCharges));
      }
    });
  };

  const [text_loader, setTextLoader] = useState(false);

  const onSubmitInteract = async (e) => {
    e.preventDefault();
    if (text_loader != true) {
      setTextLoader(true);
      const formData = new FormData();
      formData.append("order_id", localStorage.getItem("order_id"));
      formData.append("txn_type", "interac");
      formData.append("grand_total", interac_total);
      formData.append("interac_file", file);
      try {
        const response = await axios({
          method: "post",
          url: "https://api.rollzfranchise.com/api/ipayment_status_change",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          swal({
            text: "Order Placed Successfully",
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then(async (data) => {
            localStorage.removeItem("order_id");
            navigate("/ordering");
          });
          setTextLoader(false);
        });
      } catch (err) {
        console.log(err);
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        });
        setTextLoader(false);
      }
    }
  };
  return (
    <>
      <div>
        {isLoading && <Loader />}

        <Modal show={show} size="md" centered>
          <Modal.Header className="bg-blue text-white">
            <Modal.Title className="fw-bold">PayPal Payment</Modal.Title>
            <div
              className="closeModalBtn"
              onClick={() => window.location.reload()}
            >
              &times;
            </div>
          </Modal.Header>
          <Modal.Body className="bg-light p-4 pb-3">
            <div>
              <div className="col-md-12 pe-md-0">
                <div
                  className="white-box  bg-light shadow-none"
                  style={{ marginTop: "0px", padding: "0px" }}
                >
                  <h2>Cart total</h2>

                  <div className="mt-3">
                    <div className="d-flex justify-content-between py-1">
                      <span>Sub total (EXCL. TAX)</span>
                      <span>${parseFloat(subtotal).toFixed(2)}</span>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <span>Shipping charges</span>
                      <span>
                        $
                        {shipping != 0
                          ? parseFloat(shipping_charges).toFixed(2)
                          : (0.0).toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <span>Handling charges</span>
                      <span>${handling_charges}</span>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <span>Tax (HST)</span>
                      <span>${parseFloat(total_tax).toFixed(2)}</span>
                    </div>
                    {payMethod == "paypal" ? (
                      <div className="d-flex justify-content-between py-1">
                        <span>Paypal Charge (3.98%)</span>
                        <span>${parseFloat(PaypalCharges).toFixed(2)}</span>
                      </div>
                    ) : null}

                    <div className="d-flex justify-content-between border-top text-pink mt-3 pt-3 mb-1 py-1">
                      <h4 className="fw-bold">Grand total =</h4>
                      <h4 className="fw-bold">
                        ${parseFloat(grand_total).toFixed(2)}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bd-show-btn" ref={paypalRef}></div>
              <div>
                <div className="main-head-prf">
                  <h5>OR</h5>
                </div>

                <button
                  className="button place-order-btn fw-bold w-100 btn-blue"
                  onClick={onChangePayment}
                >
                  Continue with Interac
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={interact} size="md" centered>
          <Modal.Header className="bg-blue text-white">
            <Modal.Title className="fw-bold">Interac Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light p-4 pb-3">
            <div>
              <form onSubmit={onSubmitInteract}>
                <div>
                  <div className="col-12 mb-4">
                    <label htmlFor="pick-up" className="mb-2">
                      Upload File
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="shipping_method"
                      onChange={(e) => setFile(e.target.files[0])}
                      required
                    />
                  </div>
                  <button className="button place-order-btn fw-bold w-100 btn-blue">
                    <i className="bi bi-check2-circle me-1 icon-center"></i>{" "}
                    {text_loader == false
                      ? "PLACE AN ORDER"
                      : "Order is Placing . Please Wait ...."}
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PayPal;
