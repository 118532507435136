import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import axios from "axios";
import Loader from "../Loader";
import moment from "moment/moment";
import swal from "sweetalert";

const Offers = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    const navigate = useNavigate();

    useEffect(() => {
        if (
            !localStorage.getItem("vendor_id") &&
            !localStorage.getItem("auth_name")
        ) {
            navigate("/login");
        }
    }, []);

    const [offers, setOffers] = useState([]);
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        fetchOffers()
    }, [])

    const fetchOffers = async () => {
        setLoading(true)
        try {
            const response = await axios({
                method: "get",
                url: "https://api.rollzfranchise.com/api/rollz_offers",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                setLoading(false)
                setOffers(response.data.data)
            })
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }



    const onStatusChange = async (offer_id, status) => {

        swal({
            title: "Are you sure?",
            text: "You want to Change the Status",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {

                setLoading(true)
                const formData = new FormData()
                formData.append("offer_id", offer_id)
                formData.append("is_active", status == 1 ? 0 : 1)
                try {
                    const response = await axios({
                        method: "post",
                        url: "https://api.rollzfranchise.com/api/change_offer_status",
                        data: formData,
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                        },
                    }).then(response => {
                        swal({
                            icon: "success",
                            text: "Offers Status Changed Successfully",
                        }).then(data => {
                            setOffers(response.data.data)
                        })
                        setLoading(false)
                    })
                } catch (err) {
                    swal({
                        icon: "error",
                        text: "SomeThing Went Wrong",
                    })
                    setLoading(false)
                }
            } else {
                setOffers((data) => [...data])
            }
        })
    }

    const deleteRow = (offer_id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to remove this Offer",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {

                setLoading(true)
                const formData = new FormData()
                formData.append("offer_id", offer_id)
                try {
                    const response = await axios({
                        method: "post",
                        url: "https://api.rollzfranchise.com/api/delete_offer",
                        data: formData,
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                        },
                    }).then(response => {
                        swal({
                            icon: "success",
                            text: "Offer Deleted Successfully",
                        }).then(data => {
                            setOffers(response.data.data)
                        })
                        setLoading(false)
                    })
                } catch (err) {
                    swal({
                        icon: "error",
                        text: "SomeThing Went Wrong",
                    })
                    setLoading(false)
                }
            }
        })
    }

    return (
        <>
            {isLoading && <Loader />}
            <Navbar />
            <section className="pb-5 pt-4 mt-2" style={{ minHeight: "70vh" }}>
                <div className="container-box">
                    <div className="top-heading">
                        <h1>Offers</h1>
                    </div>

                    <div className="row mb-4 justify-content-center">
                        <div className="col-12">
                            <div className="white-box p-4">
                                <div className="text-end">
                                    <Link
                                        to="/add-offer"
                                        className="button btn-blue text-decoration-none"
                                    >
                                        <span className="fw-bold me-1 icon-center">+</span> Add
                                        Offer
                                    </Link>
                                </div>

                                <div className="table-responsive mt-4">
                                    <table className="table table-bordered text-center mobile-table">
                                        <thead className="table-danger">
                                            <tr>

                                                <th >Id</th>
                                                <th >Image</th>
                                                <th >Offer Title</th>
                                                <th >Discount (%)</th>
                                                <th >Start Date </th>
                                                <th >End Date</th>
                                                <th >Status</th>
                                                <th >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                offers.map((offer, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{offer.id}</td>
                                                            <td>
                                                                <img src={`https://ztpl.net/rollz_api/application/storage/app/public/${offer.img}`} alt="offerImage" 
                                                                style={{ width: "40px", height: "40px", objectFit: "cover" }} />
                                                            </td>
                                                            <td>{offer.title}</td>
                                                            <td>{offer.discount}</td>
                                                            <td>{offer.startdate}</td>
                                                            <td>{offer.enddate}</td>
                                                            <td>
                                                                <div className="form-check form-switch form-switch-md mb-0 d-flex justify-content-center align-items-end">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input fs-5 me-0"
                                                                        // id={"customSwitchsizemd" + offer.id}
                                                                        checked={offer.is_active == 1}
                                                                        onChange={
                                                                            () => {
                                                                                onStatusChange(offer.id, offer.is_active)
                                                                            }
                                                                        }
                                                                    />
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <Link to={`/edit-offer/${offer.id}`} className="btn btn-primary btn-sm bg-blue">
                                                                    <i className="bi bi-pencil-fill me-1"></i> Edit
                                                                </Link>
                                                                <button onClick={() => deleteRow(offer.id)} className="btn btn-danger btn-sm ms-3">
                                                                    <i className="bi bi-trash me-1"></i> Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Offers;
