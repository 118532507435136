import React, { useEffect, useState } from "react";
import "../../assets/css/Login.css";

import { Link, useNavigate } from "react-router-dom";

// images
import logo from "../../assets/images/ice-logo.png";
import loginImg from "../../assets/images/login_icecrem.png";
import axios from "axios";
import Loader from "../Loader";
import NewPassword from "./NewPassword";
import { config } from "../../config";

const Login1 = () => {
  // login data
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPwd, setShowPwd] = useState(false);

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    localStorage.setItem("vendor_id", "2");
    localStorage.setItem("auth_Name", "Franchise");
    navigate("/");
  };

  const [isLoading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [reset, setReset] = useState(false)
  const [customer_id ,setCustomerId] = useState('')
  const [customer_name ,setCustomerName] = useState('')
  const [customer_pass ,setCustomerPass] = useState('')


  const onLoginPage = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiurl}customer_login`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.data.success == true) {
          localStorage.setItem("vendor_id", response.data.data[0]?.id);
          if(response.data.data[0]?.pass_reset == 0){
            setReset(true)
            setCustomerId(response.data.data[0]?.id)
            setCustomerName(response.data.data[0]?.first_name + " " + response.data.data[0]?.last_name)
            setCustomerPass(response.data.data[0]?.pass_reset)
            return;
          }
          localStorage.setItem('pass_reset', response.data.data[0]?.pass_reset)

          localStorage.setItem(
            "auth_name",
            response.data.data[0]?.first_name +
            " " +
            response.data.data[0]?.last_name
          );
          navigate("/");
        }
        setLoading(false);
      });
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };


  if(reset){
    return <NewPassword customer_name={customer_name} customer_id={customer_id} customer_pass={customer_pass} />
  }


  return (
    <>
      {isLoading && <Loader />}
      <section className="login-back">
        <div className="login-box justify-space-between">
          <div className="login-form shadow">
            <form onSubmit={onLoginPage}>
              <div className="text-center">
                <div className="position-relative">
                  <img src={logo} className="rollz-logo" alt="logo" />
                  <br />
                </div>

                <h5 className="fw-bold text-blue pb-0 my-3">Login to</h5>
                <h2 className="fw-bolder text-pink">Rollz ice cream</h2>
                <p className="my-2 text-blue">Franchise Ordering System</p>
              </div>

              <div className="mt-4 position-relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError(false);
                  }}
                  placeholder="Email Address"
                  required
                  className={
                    error == true
                      ? "input-field bg-light red-border"
                      : "input-field bg-light border"
                  }
                />
                <i className="bi bi-envelope-fill input-icon"></i>
              </div>
              {error == true ? (
                <span className="err-message">
                  * Please Enter Correct Email Address
                </span>
              ) : null}

              <div className="mt-3 position-relative">
                <input
                  type={`${showPwd ? "text" : "password"}`}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError(false);
                  }}
                  placeholder="Password"
                  className={
                    error == true
                      ? "input-field bg-light red-border"
                      : "input-field bg-light border"
                  }
                  required
                />
                {error == true ? (
                  <span className="err-message">
                    * Please Enter Correct Password
                  </span>
                ) : null}
                <i className="bi bi-lock-fill input-icon"></i>
                <i
                  className={`bi bi-eye${showPwd ? "-slash" : ""
                    }-fill eye-icon`}
                  onClick={() => setShowPwd(!showPwd)}
                ></i>
              </div>

              {/* <div className="my-3 text-center">
                <Link
                  to="/forget-password"
                  className="py-3 text-decoration-none text-pink"
                >
                  Forgot your password ?
                </Link>
              </div> */}

              <div className="mt-5">
                <button className="login-btn">
                  <i className="bi bi-box-arrow-in-right me-1"></i> Log In
                </button>
              </div>
            </form>
          </div>
          <div className="bd-login-img">
            <h1 className="login-head">A cup full of happiness</h1>
            <img src={loginImg} alt="no Img" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Login1;
