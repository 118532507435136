import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import axios from "axios";
import Loader from "../Loader";

import profile from "../../assets/images/signup.webp"
import ResetPassword from "./ResetPassword";
import swal from "sweetalert";

const Credential = () => {
    useEffect(() => window.scrollTo(0, 0), []);

    const [isLoading, setLoading] = useState(false);
    const [data, setdata] = useState([]);
    const [training, setTraining] = useState([]);

    const [userName, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        if (
            !localStorage.getItem("vendor_id") &&
            !localStorage.getItem("auth_name")
        ) {
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("vendor_id"))

                const res = await axios.post("https://api.rollzfranchise.com/api/show_vendor", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                })
                setLoading(false)
                console.log("res", res)
                setUsername(res.data.data.username)
                setEmail(res.data.data.email)
                setPassword(res.data.data.password_str)

            } catch (error) {
                console.log(error)
                swal("", "Something Went Wrong", "error")
                setLoading(false)
            }
        }

        getData()
    }, [])

    const [category_id, setCategory] = useState("");

    return (
        <>
            {isLoading && <Loader />}
            <Navbar />
            <section className="pb-5 pt-4 mt-2" >
                <div className="container-box">
                    <div className="top-heading">
                        <h1>App Credentials</h1>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="white-box py-4 px-md-5 px-4">
                                <div className="row align-items-center">
                                    <div className="col-lg-2 col-md-3">
                                        <img src={profile} alt="" style={{ width: "120px" }} />
                                    </div>
                                    <div className="col-lg-8 col-md-9 mt-md-0 mt-4">
                                        <div className="d-flex credential-text">
                                            <div className="me-md-5 me-2 ">
                                                <h5 className="lh-lg fw-bold">User Name : </h5>
                                                <h5 className="lh-lg fw-bold">Email <span className="d-sm-inline d-none">Address</span> : </h5>
                                                <h5 className="lh-lg fw-bold">Password : </h5>
                                            </div>

                                            <div>
                                                <h5 className="lh-lg">{userName} </h5>
                                                <h5 className="lh-lg"> {email} </h5>
                                                <h5 className="lh-lg"> {password}</h5>
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <ResetPassword button={{
                                                name: "Reset Credential",
                                                type: 1,
                                                uname: userName,
                                                password: password
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Credential;
