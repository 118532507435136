import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import axios from "axios";
import Loader from "../Loader";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const OrderHistory = () => {
  const [showForm, setShowForm] = useState(false);

  const [show, setShow] = useState(false);

  useEffect(() => window.scrollTo(0, 0), []);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetch = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("customer_id", localStorage.getItem("vendor_id"));
    try {
      const response = await axios({
        method: "post",
        url: "https://api.rollzfranchise.com/api/customer_order",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setData(response.data.data);
        setDisplayData(response.data.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [order, setOrder] = useState("");
  const [pay_status, setPayStatus] = useState("");
  const [order_status, setorderStatus] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [view_detail, setViewDetail] = useState([]);

  const onFilter = (e) => {
    e.preventDefault();
    setLoading(true);

    const filteredData = displayData.filter((item) => {
      const date = item.date_add.split(" ")[0];

      const fromDate = from ? new Date(from) : null;
      const toDate = to ? new Date(to) : null;
      const itemDate = new Date(date);

      const number = order ? item.id == order : true;

      const PaymentStatus = pay_status
        ? item.payment_status.toLowerCase() == pay_status.toLowerCase()
        : true;

      const orderStatus = order_status
        ? item.order_status.toLowerCase() == order_status.toLowerCase()
        : true;
      const isDateInRange =
        from && to ? itemDate >= fromDate && itemDate <= toDate : true;

      return number && PaymentStatus && orderStatus && isDateInRange;
    });

    setData(filteredData);
    function myFunction() {
      setLoading(false);
    }
    setTimeout(myFunction, 500);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2" style={{ minHeight: "70vh" }}>
        <div className="container-box">
          <div className="top-heading">
            <h1>Orders History</h1>
          </div>

          <div className="row justify-content-center position-relative">
            <div className="col-12">
              <div className="white-box p-4">
                <form onSubmit={onFilter}>
                  {/* search order form */}
                  {showForm ? (
                    <div className="mb-5">
                      <h2 className="d-flex justify-content-between flex-md-row flex-column align-items-center">
                        <span className="pb-md-0 pb-4">Search orders</span>
                        {showForm ? (
                          <div>
                            <button
                              onClick={() => {
                                setOrder("");
                                setPayStatus("");
                                setFrom("");
                                setTo("");
                                setData(displayData);
                              }}
                              type="reset"
                              className="button btn-pink me-3"
                            >
                              <i className="bi bi-arrow-repeat me-1"></i> Reset
                            </button>

                            <button className="button btn-blue me-3">
                              <i className="bi bi-search me-1"></i> Search
                            </button>
                          </div>
                        ) : null}
                      </h2>
                      <div className="row">
                        <div className="col-md-2 mt-4">
                          <label htmlFor="" className="mb-1">
                            Order Number
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-0 py-2"
                            placeholder="Enter Order No."
                            value={order}
                            onChange={(e) => setOrder(e.target.value)}
                          />
                        </div>

                        <div className="col-md-3 mt-4">
                          <label htmlFor="" className="mb-1">
                            Payment Status
                          </label>
                          <select
                            name=""
                            id=""
                            className="form-select rounded-0 py-2"
                            value={pay_status}
                            onChange={(e) => setPayStatus(e.target.value)}
                          >
                            <option value="">--Select Status</option>
                            <option value="Paid">Paid</option>
                            <option value="UnPaid">UnPaid</option>
                          </select>
                        </div>

                        <div className="col-md-3 mt-4">
                          <label htmlFor="" className="mb-1">
                            Order Status
                          </label>
                          <select
                            name=""
                            id=""
                            className="form-select rounded-0 py-2"
                            value={order_status}
                            onChange={(e) => setorderStatus(e.target.value)}
                          >
                            <option value="">--Select Status</option>
                            <option value="Processing">Processing</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Completed</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                        </div>

                        <div className="col-md-2 mt-4">
                          <label htmlFor="" className="mb-1">
                            From date
                          </label>
                          <input
                            type="date"
                            className="form-control rounded-0 py-2"
                            placeholder="Enter Order No."
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                          />
                        </div>
                        <div className="col-md-2 mt-4">
                          <label htmlFor="" className="mb-1">
                            To date
                          </label>
                          <input
                            type="date"
                            className="form-control rounded-0 py-2"
                            placeholder="Enter Order No."
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* -------------------------- */}
                  <div>
                    <h2 className="d-flex justify-content-between align-items-center">
                      <span>Search Results</span>

                      <div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowForm(!showForm);
                            setData(displayData);
                          }}
                          className={`button ${showForm ? "bg-danger" : "btn-blue"
                            } px-3`}
                          title="Search Records"
                        >
                          {showForm ? (
                            <>
                              {" "}
                              <i
                                className="bi bi-x d-in"
                                style={{ transform: "scale(1.05)" }}
                              >
                                {" "}
                              </i>{" "}
                              {"Close"}{" "}
                            </>
                          ) : (
                            <>
                              <i className="bi bi-funnel-fill me-1"></i>{" "}
                              {"Filter Orders"}
                            </>
                          )}
                        </button>
                      </div>
                    </h2>
                  </div>
                </form>

                <div className="table-responsive">
                  <table className="table table-bordered text-center mobile-table">
                    <thead className="table-danger">
                      <tr>
                        <th className="col-1">Order_Id</th>
                        <th className="col-1">Date</th>
                        <th className="col-1">Total</th>
                        <th className="col-1">Payment status</th>
                        <th className="col-1">Order status</th>
                        <th className="col-1">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.map((item, index) => {
                        let Status;
                        if (item.payment_status.toLowerCase() == "paid") {
                          Status = (
                            <span className="badge bg-success">Paid</span>
                          );
                        } else {
                          Status = (
                            <span className="badge bg-danger">Unpaid</span>
                          );
                        }

                        const onViewDetail = async (e) => {
                          setShow(true);
                          e.preventDefault();
                          setLoading(true);
                          try {
                            const response = await axios({
                              method: "get",
                              url: `https://api.rollzfranchise.com/api/orders/${item.id}`,
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                              },
                            }).then((response) => {
                              setViewDetail(response.data.data);
                              setLoading(false);
                            });
                          } catch (err) {
                            swal({
                              icon: "error",
                              text: "SomeThing Went Wrong",
                            });
                            setLoading(false);
                          }
                        };

                        return (
                          <tr key={index}>
                            <td>#{item.id}</td>
                            <td>
                              {moment(item.date_add).format("DD-MM-YYYY")}
                            </td>
                            {/* <td>127, Gotri Road</td> */}
                            <td>${parseFloat(item.grand_total).toFixed(2)}</td>
                            <td>{Status}</td>
                            <td style={{ textTransform: "capitalize" }}>
                              {item.order_status}
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-secondary btn-sm"
                                onClick={onViewDetail}
                              >
                                <i className="bi bi-eye-fill"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-12 search-order-form"></div>
          </div>
        </div>
      </section>

      <Footer />

      <Modal show={show} size="lg" centered>
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Order Details</Modal.Title>
          <div className="closeModalBtn" onClick={() => setShow(false)}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Body className="bg-light p-4 pb-3">
          <div>
            {view_detail.order?.map((order, i) => {
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="  text-pink fw-bold mb-0">
                          Order ID :{" "}
                        </h6>
                        <span className="ms-3"> # {order.id}</span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="text-pink fw-bold mb-0">Email Id : </h6>
                        <span className="ms-3">{order.email}</span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="text-pink fw-bold mb-0">Address :</h6>
                        <span
                          dangerouslySetInnerHTML={{ __html: order.address }}
                        ></span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div>
                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Date and Time :
                          </h6>
                          <span className="ms-3">
                            {moment(order.date_add).format(
                              "DD-MM-YYYY h:mm:ss "
                            )}
                          </span>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Payment Status :
                          </h6>
                          <span className="ms-3">{order.payment_status}</span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Order Status :{" "}
                          </h6>
                          <span className="ms-3">{order.order_status}</span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-12">
                    <div className="d-flex align-items-center mb-2">
                      <h6 className=" text-pink fw-bold mb-0">Order Note : </h6>
                      <span className="ms-3">
                        This is dummy note for the order
                      </span>
                    </div>
                  </div> */}
                    <div className="table-responsive col-12 mt-2 mb-2 fixed-table-header">
                      <table className="table table-bordered table-striped text-center">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Tax</th>
                            <th>Total</th>
                          </tr>
                        </thead>

                        <tbody>
                          {view_detail.order_items?.map((items, i) => {
                            const Total =
                              parseFloat(items.item_price).toFixed(2) *
                              parseFloat(items.item_quantity) +
                              parseFloat(items.tax_amount);

                            return (
                              <tr key={i + "item"}>
                                <td>{items.id_item}</td>
                                <td className="text-left">{items.item_name}</td>
                                <td>
                                  ${parseFloat(items.item_price).toFixed(2)}
                                </td>
                                <td>{items.item_quantity}</td>
                                <td>{items.tax_amount}</td>
                                <td>${parseFloat(Total).toFixed(2)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row bg-light">
                    <div className="col-md-6 ps-md-0 border-end">
                      <div className="white-box px-4 py-2 bg-light shadow-none">
                        <div className="row">
                          <div className="col-6 pt-2">
                            <label className="mb-2 color-pink">
                              Shipping method
                            </label>
                            <input
                              type="text"
                              value="Shipping"
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>

                          <div className="col-6 pt-2">
                            <label className="mb-2 color-pink">
                              Payment method
                            </label>
                            <input
                              type="text"
                              value="Paypal"
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>

                          <div className="col-12 pt-3">
                            <label className="mb-2 color-pink">
                              Paypal transaction status
                            </label>
                            <input
                              type="text"
                              value={order.paypal_status}
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>
                          <div className="col-12 pt-3">
                            <label className="mb-2 color-pink">
                              Paypal transaction id :
                            </label>
                            <input
                              type="text"
                              value={order.paypal_txn_id}
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 pe-md-0">
                      <div className="white-box px-4 py-2 bg-light shadow-none">
                        <h2>Cart total</h2>

                        <div className="mt-3">
                          <div className="d-flex justify-content-between py-1">
                            <span>Sub total (EXCL. TAX)</span>
                            <span>
                              ${parseFloat(order.item_total).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Shipping charges</span>
                            <span>
                              ${parseFloat(order.shipping_amount).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Handling charges</span>
                            <span>
                              ${parseFloat(order.handling_charge).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Discount</span>
                            <span>
                              ${parseFloat(order.discount_amount).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Tax (HST)</span>
                            <span>
                              ${parseFloat(order.tax_amount).toFixed(2)}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between border-top text-pink mt-3 pt-3 mb-1 py-1">
                            <h4 className="fw-bold">Grand total =</h4>
                            <h4 className="fw-bold">
                              ${parseFloat(order.grand_total).toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderHistory;
