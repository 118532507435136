import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import axios from "axios";
import Loader from "../Loader";
import moment from "moment/moment";
import swal from "sweetalert";

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"


const EditOffers = () => {

    const [title, setTitle] = useState("")
    const [discount, setDiscount] = useState("")
    const [startdate, setStartDate] = useState("")
    const [enddate, setEndDate] = useState("")
    const [photo, setPhoto] = useState("")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("")
    const [isLoading, setLoading] = useState(false);

    useEffect(() => window.scrollTo(0, 0), []);

    const navigate = useNavigate();

    useEffect(() => {
        if (
            !localStorage.getItem("vendor_id") &&
            !localStorage.getItem("auth_name")
        ) {
            navigate("/login");
        }
    }, []);


    const params = useParams()

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setLoading(true)
        try {
            const response = await axios({
                method: "get",
                url: `https://api.rollzfranchise.com/api/view_offer/${params.offer_id}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                setLoading(false)
                setTitle(response.data.data.title)
                setDiscount(response.data.data.discount)
                setStatus(
                    optionStatus.find(
                        values => values.value == response.data.data.is_active
                    )
                )
                setStartDate(response.data.data.startdate)
                setEndDate(response.data.data.enddate)
                setPhoto(response.data.data.img)
                setDescription(response.data.data.description)
            })
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const onEditOffer = async e => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("offer_id", params.offer_id)
        formData.append("title", title)
        formData.append("discount", discount)
        formData.append("startdate", startdate)
        formData.append("enddate", enddate)
        formData.append("photo", photo)
        formData.append("description", description)
        formData.append("is_active", status)
        try {
            const response = await axios({
                method: "post",
                url: "https://api.rollzfranchise.com/api/edit_offer",
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                swal({
                    icon: "success",
                    text: "Offer Edited Successfully",
                }).then(data => {
                    setLoading(false)
                    navigate(-1)
                })
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const optionStatus = [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
    ]

    return (
        <>
            {isLoading && <Loader />}
            <Navbar />
            <section className="pb-5 pt-4 mt-2" style={{ minHeight: "70vh" }}>
                <div className="container-box">
                    <div className="top-heading">
                        <h1>Edit Offer</h1>
                    </div>

                    <div className="row mb-4 justify-content-center">
                        <div className="col-12">
                            <div className="white-box p-4">
                                <div className="text-end">
                                    {/* <Link
                                        to="/ordering/add-order"
                                        className="button btn-blue text-decoration-none"
                                    >
                                        <span className="fw-bold me-1 icon-center">+</span> Add
                                        Offer
                                    </Link> */}
                                </div>

                                <form onSubmit={onEditOffer}>

                                    <div>
                                        <div>
                                            <div className="row">
                                                <div className=" text-end mb-4">
                                                    <button className="button btn-blue me-2 ">
                                                        <i className="bi bi-send me-1"></i> Save
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => navigate(-1)}
                                                        className="button button btn-pink"
                                                    >
                                                        <i className="fas fa-times me-1"></i> Cancel
                                                    </button>
                                                </div>
                                                <div className="mb-4 col-md-4">
                                                    <label className="form-label">
                                                        Title <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-0 py-2"
                                                        placeholder="Enter Title"
                                                        value={title}
                                                        onChange={e => setTitle(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-4 col-md-4">
                                                    <label className="form-label">
                                                        Discount <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-0 py-2"
                                                        placeholder="Enter Discount"
                                                        value={discount}
                                                        onChange={e => setDiscount(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-4 col-md-4">
                                                    <label className="form-label">
                                                        Start Date <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control rounded-0 py-2"
                                                        placeholder="Enter Start Date"
                                                        value={startdate}
                                                        onChange={e => setStartDate(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-4 col-md-4">
                                                    <label className="form-label">
                                                        End Date <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control rounded-0 py-2"
                                                        placeholder="Enter End Date"
                                                        value={enddate}
                                                        onChange={e => setEndDate(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-4 col-md-4">
                                                    <label htmlFor="formFile" className="form-label">
                                                        Upload Photo

                                                        <img
                                                            src={`https://ztpl.net/rollz_api/application/storage/app/public/${photo}`}
                                                            alt="no img"
                                                            width="20px"
                                                            height="20px"
                                                            style={{ marginLeft: "10px" }}
                                                        />
                                                    </label>
                                                    <input
                                                        className="form-control rounded-0 py-2"
                                                        type="file"
                                                        id="formFile"
                                                        accept="image/*"
                                                        onChange={e => setPhoto(e.target.files[0])}
                                                    // style={{ padding: "8px" }}
                                                    />
                                                </div>
                                                {/* <div className="mb-4 col-md-4">
                                                    <label className="form-label">
                                                        Is Active ? <span className="text-danger">*</span>
                                                    </label>
                                                    <select value={status} onChange={(e) => setStatus(e.target.value)} className="form-select rounded-0 py-2">
                                                        <option value="">Select Status</option>
                                                        {
                                                            optionStatus.map((opt) => {
                                                                return <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <div className="row">
                                                <div className="mb-3 col-md-12">
                                                    <label>Description</label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={description}
                                                        onReady={editor => { }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData()
                                                            setDescription(data)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default EditOffers;
