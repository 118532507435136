import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import axios from "axios";
import Loader from "../Loader";
import moment from "moment/moment";
import swal from "sweetalert";

const Ordering = () => {
  const [show, setShow] = useState(false);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    fetch();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetch = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("customer_id", localStorage.getItem("vendor_id"));
    try {
      const response = await axios({
        method: "post",
        url: "https://api.rollzfranchise.com/api/customer_order",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setData(response.data.data.reverse());
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const AllStatus = [
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Processing",
      value: "processing",
    },
    {
      label: "Shipped",
      value: "shipped",
    },
    {
      label: "Delivered",
      value: "Delivered",
    },
  ];

  const [view_detail, setViewDetail] = useState([]);

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2" style={{ minHeight: "70vh" }}>
        <div className="container-box">
          <div className="top-heading">
            <h1>Ordering</h1>
          </div>

          <div className="row mb-4 justify-content-center">
            <div className="col-12">
              <div className="white-box p-4">
                <div className="text-end">
                  <Link
                    to="/ordering/add-order"
                    className="button btn-blue text-decoration-none"
                  >
                    <span className="fw-bold me-1 icon-center">+</span> Add
                    Order
                  </Link>
                </div>

                <div className="table-responsive mt-4">
                  <table className="table table-bordered text-center mobile-table">
                    <thead className="table-danger">
                      <tr>
                        <th className="col-1">Order_Id</th>
                        <th className="col-1">Date</th>
                        <th className="col-1">Total</th>
                        <th className="col-1">Payment Method</th>
                        <th className="col-1">Payment status</th>
                        <th className="col-1">Order status</th>
                        <th className="col-1" style={{ width: "6%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.map((item, index) => {
                        let Status;
                        if (item.payment_status.toLowerCase() == "paid") {
                          Status = (
                            <span className="badge bg-success">Paid</span>
                          );
                        } else {
                          Status = (
                            <span className="badge bg-danger">Unpaid</span>
                          );
                        }

                        const onChangeStatus = (e) => {
                          swal({
                            title: "Are you sure?",
                            text: "Are you sure you want to Change the Status",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              e.preventDefault();
                              setLoading(true);
                              const formData = new FormData();
                              formData.append("order_id", item.id);
                              formData.append("order_status", e.target.value);
                              try {
                                const response = await axios({
                                  method: "post",
                                  url: "https://api.rollzfranchise.com/api/order_update_status",
                                  data: formData,
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "multipart/form-data",
                                  },
                                }).then((response) => {
                                  swal({
                                    icon: "success",
                                    text: "Status Changed Successfully",
                                  }).then((data) => {});
                                  setLoading(false);
                                });
                              } catch (err) {
                                swal({
                                  icon: "error",
                                  text: "SomeThing Went Wrong",
                                });
                                setLoading(false);
                              }
                            }
                          });
                        };

                        const onViewDetail = async (e) => {
                          setShow(true);
                          e.preventDefault();
                          setLoading(true);
                          try {
                            const response = await axios({
                              method: "get",
                              url: `https://api.rollzfranchise.com/api/orders/${item.id}`,
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                              },
                            }).then((response) => {
                              setViewDetail(response.data.data);
                              setLoading(false);
                            });
                          } catch (err) {
                            swal({
                              icon: "error",
                              text: "SomeThing Went Wrong",
                            });
                            setLoading(false);
                          }
                        };

                        return (
                          <tr key={index}>
                            <td>#{item.id}</td>
                            <td>
                              {moment(item.date_add).format("DD-MM-YYYY")}
                            </td>
                            {/* <td>127, Gotri Road</td> */}
                            <td>${parseFloat(item.grand_total).toFixed(2)}</td>
                            <td>{item.paypal_txn_type}</td>
                            <td>{Status}</td>
                            <td>
                              <select
                                className="form-select rounded-0 py-1"
                                onChange={onChangeStatus}
                              >
                                {AllStatus.map((pay, id) => {
                                  return (
                                    <option
                                      value={pay.value}
                                      selected={
                                        item.order_status.toLowerCase() ==
                                        pay.value.toLowerCase()
                                      }
                                    >
                                      {pay.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td className="text-left " style={{ width: "6%" }}>
                              <button
                                className="btn btn-outline-secondary btn-sm"
                                onClick={onViewDetail}
                              >
                                <i className="bi bi-eye-fill"></i>
                              </button>
                              {item.paypal_txn_type == "interac" ? (
                                <a
                                  className="btn btn-outline-secondary btn-sm ms-2"
                                  href={`https://api.rollzfranchise.com/application/storage/app/public/${item.interact_file}`}
                                  download
                                  target="_blank"
                                >
                                  <i className="bi bi-download"></i>
                                </a>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <Modal show={show} size="lg" centered>
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Order Details</Modal.Title>
          <div className="closeModalBtn" onClick={() => setShow(false)}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Body className="bg-light p-4 pb-3">
          <div>
            {view_detail.order?.map((order, i) => {
              console.log(order)
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="  text-pink fw-bold mb-0">
                          Order ID :{" "}
                        </h6>
                        <span className="ms-3"> # {order.id}</span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="text-pink fw-bold mb-0">Email Id : </h6>
                        <span className="ms-3">{order.email}</span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="text-pink fw-bold mb-0">Address :</h6>
                        <span
                          dangerouslySetInnerHTML={{ __html: order.address }}
                        ></span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div>
                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Date and Time :
                          </h6>
                          <span className="ms-3">
                            {moment(order.date_add).format(
                              "DD-MM-YYYY h:mm:ss "
                            )}
                          </span>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Payment Status :
                          </h6>
                          <span className="ms-3">{order.payment_status}</span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Order Status :{" "}
                          </h6>
                          <span className="ms-3">{order.order_status}</span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-12">
                    <div className="d-flex align-items-center mb-2">
                      <h6 className=" text-pink fw-bold mb-0">Order Note : </h6>
                      <span className="ms-3">
                        This is dummy note for the order
                      </span>
                    </div>
                  </div> */}
                    <div className="table-responsive col-12 mt-2 mb-2 fixed-table-header">
                      <table className="table table-bordered table-striped text-center">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Tax</th>
                            <th>Total</th>
                          </tr>
                        </thead>

                        <tbody>
                          {view_detail.order_items?.map((items, i) => {
                            const Total =
                              parseFloat(items.item_price).toFixed(2) *
                                parseFloat(items.item_quantity) +
                              parseFloat(items.tax_amount);
                            return (
                              <tr key={i + "item"}>
                                <td>{items.id_item}</td>
                                <td className="text-left">{items.item_name}</td>
                                <td>
                                  ${parseFloat(items.item_price).toFixed(2)}
                                </td>
                                <td>{items.item_quantity}</td>
                                <td>{items.tax_amount}</td>
                                <td>${parseFloat(Total).toFixed(2)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row bg-light">
                    <div className="col-md-6 ps-md-0 border-end">
                      <div className="white-box px-4 py-2 bg-light shadow-none">
                        <div className="row">
                          <div className="col-6 pt-2">
                            <label className="mb-2 color-pink">
                              Shipping method
                            </label>
                            <input
                              type="text"
                              value={order.shipping_option == 0 ? "Pickup" : "Shippinig"}
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>

                          <div className="col-6 pt-2">
                            <label className="mb-2 color-pink">
                              Payment method
                            </label>
                            <input
                              type="text"
                              value={order.paypal_txn_type}
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>
                          {order.paypal_txn_type == "interac" ? (
                            <div className="col-12 pt-3">
                              <label className="mb-2 color-pink mb-2 pb-2">
                                Interac Receipt
                              </label>
                              <br />
                              <a
                                class="button btn-blue text-decoration-none"
                                href={`https://api.rollzfranchise.com/application/storage/app/public/${order.interact_file}`}
                                style={{ backgroundColor: "#ebab1f" }}
                                download
                                target="_blank"
                              >
                                <i className="bi bi-download pe-2"></i>
                                Download
                              </a>
                            </div>
                          ) : null}

                          {order.paypal_txn_type == "paypal" ? (
                            <>
                              <div className="col-12 pt-3">
                                <label className="mb-2 color-pink">
                                  Paypal transaction status
                                </label>
                                <input
                                  type="text"
                                  value={order.paypal_status}
                                  className="form-control"
                                  id="0"
                                  disabled
                                />
                              </div>
                              <div className="col-12 pt-3">
                                <label className="mb-2 color-pink">
                                  Paypal transaction id :
                                </label>
                                <input
                                  type="text"
                                  value={order.paypal_txn_id}
                                  className="form-control"
                                  id="0"
                                  disabled
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 pe-md-0">
                      <div className="white-box px-4 py-2 bg-light shadow-none">
                        <h2>Cart total</h2>

                        <div className="mt-3">
                          <div className="d-flex justify-content-between py-1">
                            <span>Sub total (EXCL. TAX)</span>
                            <span>
                              ${parseFloat(order.item_total).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Shipping charges</span>
                            <span>
                              ${parseFloat(order.shipping_amount).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Handling charges</span>
                            <span>
                              ${parseFloat(order.handling_charge).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Discount</span>
                            <span>
                              ${parseFloat(order.discount_amount).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Tax (HST)</span>
                            <span>
                              ${parseFloat(order.tax_amount).toFixed(2)}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between border-top text-pink mt-3 pt-3 mb-1 py-1">
                            <h4 className="fw-bold">Grand total =</h4>
                            <h4 className="fw-bold">
                              ${parseFloat(order.grand_total).toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Ordering;
