import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import { Link, useNavigate } from "react-router-dom";
import { config } from "../../config";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Logo from "../../assets/images/ice-logo.png";
import swal from "sweetalert";
import Loader from "../Loader";

const EditProfile = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);


  useEffect(() => window.scrollTo(0, 0), []);
  const [isLoading, setLoading] = useState(false);
  const [fname, setFName] = useState("");
  const [lname, setlName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [is_active, setIsActive] = useState("");
  const [shipping, setShipping] = useState("");
  const [handling, setHandling] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${config.apiurl}customer/${localStorage.getItem("vendor_id")}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        const datas = response?.data?.data?.customer[0];
        setFName(datas?.first_name);
        setlName(datas?.last_name);
        setPhone(datas?.mobile_number);
        setAddress(datas?.address);
        setImage(datas?.user_photo);
        setEmail(datas?.email);
        setLoading(false);
        setIsActive(datas?.is_active);
        setShipping(datas?.shipping_charges);
        setHandling(datas?.handling_charge);
        setCountry(datas?.id_country);
        setState(datas?.id_state);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onEditCustomer = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("customer_id", localStorage.getItem("vendor_id"));
    formData.append("first_name", fname);
    formData.append("last_name", lname);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("shipping_charge", shipping);
    formData.append("handling_charge", handling);
    formData.append("status", is_active);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("photo", image);
    formData.append("address", address);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiurl}edit_customer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        swal({
          icon: "success",
          text: "Profile Edited Successfully",
        }).then((data) => {
            localStorage.setItem('auth_name',fname + " " +lname)
            window.location.reload()
        });
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2">
        <div className="container-box">
          <div className="top-heading">
            <h1>My Profile</h1>
          </div>
          <form onSubmit={onEditCustomer}>
            <div className="row bg-white py-4 px-md-5 px-4 mt-4">
              <div className="col-md-6">
                <div className="mt-4 position-relative">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="input-field bg-light border"
                    value={fname}
                    onChange={(e) => setFName(e.target.value)}
                    required
                  />
                  <i className="bi bi-person-fill input-icon"></i>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mt-4 position-relative">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="input-field bg-light border"
                    value={lname}
                    onChange={(e) => setlName(e.target.value)}
                    required
                  />
                  <i className="bi bi-person-fill input-icon"></i>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="mt-4 position-relative">
                  <input
                    type="number"
                    placeholder="Phone Number"
                    className="input-field bg-light border"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <i className="bi bi-telephone-fill input-icon"></i>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                {/* <div className="mt-4 position-relative d-flex gap-3">
                  <div className="col-10">
                    <input
                      type="file"
                      className="form-control rounded-0 input-field bg-light border"
                    />
                    <i className="bi bi-file-earmark-text-fill input-icon"></i>
                  </div>
                  <div>
                    <img
                      className=""
                      src={image != null ? image : Logo}
                      width="50px"
                      height="50px"
                    />
                  </div>
                </div> */}
              </div>
              <div className="col-md-12">
                <CKEditor
                  editor={ClassicEditor}
                  data={address != null ? address : ""}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setAddress(data);
                  }}
                />
              </div>
              <div className="mt-4 text-center">
                <button className="button btn-blue">SAVE CHANGES</button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default EditProfile;
