import React, { useEffect, useState } from "react";
import calender1 from "../../assets/images/calender1.png";
import calender2 from "../../assets/images/calender2.png";
import calender3 from "../../assets/images/calender3.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment/moment";
import Loader from "../Loader";

import { Link } from "react-router-dom";
import ResetPassword from "../LoginPage/ResetPassword";

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    fetch();
    fetch2();
  }, []);

  const [data, setData] = useState([]);
  const [all_data, setAllData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");

  const fetch = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("customer_id", localStorage.getItem("vendor_id"));
    try {
      const response = await axios({
        method: "post",
        url: "https://api.rollzfranchise.com/api/customer_order",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setData(response.data.data.reverse().slice(0, 10));
      setAllData(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const fetch2 = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `https://api.rollzfranchise.com/api/customer/${localStorage.getItem(
          "vendor_id"
        )}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      response.data.data.customer.map((item) => {
        setEmail(item.email);
        setAddress(item.address);
        setMobile(item.mobile_number);
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [view_detail, setViewDetail] = useState([]);
  const [show, setShow] = useState(false);

  const currentDate = new Date(); // Current date

  // Filter data for the current week
  const currentWeekData = all_data.filter((item) => {
    const itemDate = new Date(item.date_add);
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Start of the week (Sunday)
    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Saturday)
    return itemDate >= startOfWeek && itemDate <= endOfWeek;
  });

  // Filter data for the current month
  const currentMonthData = all_data.filter((item) => {
    const itemDate = new Date(item.date_add);
    return (
      itemDate.getMonth() === currentDate.getMonth() &&
      itemDate.getFullYear() === currentDate.getFullYear()
    );
  });

  // Filter data for the current year
  const currentYearData = all_data.filter((item) => {
    const itemDate = new Date(item.date_add);
    return itemDate.getFullYear() === currentDate.getFullYear();
  });

  // =============================== Loyalty Program =====================================

  const [redeemCustomer, setRedeemCustomer] = useState(null);
  const [mobileNo, setMobileNo] = useState(null);
  // modals
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [editCustomerModal, setEditCustomerModal] = useState(false);

  // For register new
  const [orderId, setOrderId] = useState("");
  const [addAmount, setAddAmount] = useState("");

  // for action add points
  const [amount, setAmount] = useState("");

  // search customer api integration
  const searchCustomer = async () => {
    try {
      setRedeemCustomer(null);
      setLoading(true);
      if (mobileNo.length == 10) {
        const formData = new FormData();
        formData.append("mobile_number", mobileNo);
        formData.append("location", localStorage.getItem("vendor_id"));
        const response = await axios.post(
          "https://api.rollzfranchise.com/api/find_customer",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);
        if (response.data.success == true) {
          setRedeemCustomer([response.data.data]);
          setOrderId(response.data.data.order_id);
        } else {
          setRedeemCustomer([]);
          setOrderId("");
        }
        setLoading(false);
      } else {
        setLoading(false);
        swal({
          icon: "warning",
          text: "Please Enter Valid Mobile Number",
        });
      }
    } catch (error) {
      console.log(error);
      setRedeemCustomer([]);
      setOrderId("");
      setLoading(false);
    }
  };

  // add New customer api integration
  const addNewCustomer = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("amount", addAmount);
      formData.append("mobile_number", mobileNo);
      formData.append("location", localStorage.getItem("vendor_id"));

      const response = await axios.post(
        "https://api.rollzfranchise.com/api/add_loyalty_customer",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      swal("Customer Added Successfully", "", {
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      swal("Something Went Wrong", "", "error");
    }
  };

  // addmore loyalty points api integration
  const addLoyaltyPoints = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("amount", amount);
      formData.append("loyalty_points", amount);
      formData.append("location", localStorage.getItem("vendor_id"));
      const response = await axios.post(
        "https://api.rollzfranchise.com/api/add_loyalty_history",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      setLoading(false);
      swal("Points Added Successfully", "", {
        icon: "success",
      }).then(() => {
        setAddCustomerModal(false);
        setOrderId("");
        setAmount("");
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      swal("Something Went Wrong", "", "error");
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2">
        <div className="container-box">
          <div className="top-heading">
            <h1>Hi, Welcome back !</h1>
          </div>

          <div className="row mt-4">
            {/* left dashboard */}
            <div className="col-lg-8 ">
              <div className="row">
                <div className="col-md-4  mb-md-0 mb-4">
                  <div className="weekly-order count-card">
                    <div className="week-calender">
                      <img src={calender1} alt="" className="img-fluid" />
                    </div>
                    <div className="ms-3">
                      <h6>Weekly orders</h6>
                      <h6> {currentWeekData?.length} </h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-md-0 mb-4">
                  <div className="monthly-order count-card">
                    <div className="month-calender">
                      <img src={calender2} alt="" className="img-fluid" />
                    </div>
                    <div className="ms-3">
                      <h6>Monthly orders</h6>
                      <h6>{currentMonthData?.length}</h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div className=" yearly-order count-card">
                    <div className="year-calender">
                      <img src={calender3} alt="" className="img-fluid" />
                    </div>
                    <div className="ms-3">
                      <h6>Yearly orders</h6>
                      <h6> {currentYearData?.length}</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="white-box">
                  <h2>Last top 10 orders</h2>

                  <div className="table-responsive mt-4">
                    <table className="table table-bordered text-center mobile-table">
                      <thead className="table-danger">
                        <tr>
                          <th className="col-1">Order_Id</th>
                          <th className="col-1">Date</th>
                          {/* <th className="col-2">Address</th> */}
                          <th className="col-1">Total</th>
                          <th className="col-1">Payment status</th>
                          <th className="col-1">Order status</th>
                          <th className="col-1">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((item, index) => {
                          let Status;
                          if (item.payment_status.toLowerCase() == "paid") {
                            Status = (
                              <span className="badge bg-success">Paid</span>
                            );
                          } else {
                            Status = (
                              <span className="badge bg-danger">Unpaid</span>
                            );
                          }

                          const onViewDetail = async (e) => {
                            setShow(true);
                            e.preventDefault();
                            setLoading(true);
                            try {
                              const response = await axios({
                                method: "get",
                                url: `https://api.rollzfranchise.com/api/orders/${item.id}`,
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "multipart/form-data",
                                },
                              });
                              setViewDetail(response.data.data);
                              setLoading(false);
                            } catch (err) {
                              swal({
                                icon: "error",
                                text: "SomeThing Went Wrong",
                              });
                              setLoading(false);
                            }
                          };

                          return (
                            <tr key={index}>
                              <td>#{item.id}</td>
                              <td>
                                {moment(item.date_add).format("DD-MM-YYYY")}
                              </td>
                              {/* <td>127, Gotri Road</td> */}
                              <td>
                                ${parseFloat(item.grand_total).toFixed(2)}
                              </td>
                              <td>{Status}</td>
                              <td>{item.order_status}</td>
                              <td>
                                <button
                                  className="btn btn-outline-secondary btn-sm"
                                  onClick={onViewDetail}
                                >
                                  <i className="bi bi-eye-fill"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* ----------- */}
            </div>

            {/* right dashboard */}
            <div className="col-lg-4">
              <div className="row1">
                {/* ---------------- */}
                <div className="col-md-12">
                  <div className="white-box mt-md-0 mt-4">
                    <h2> Loyalty Programme</h2>
                    {/*  */}
                    <div className="mt-3">
                      <div className="py-1 prefect-ice-records">
                        <div className="row">
                          <div className="col-8 pe-0">
                            <input
                              type="text"
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                              placeholder="Enter Mobile no."
                              maxLength={10}
                              className="form-control rounded-0"
                            />
                          </div>
                          <div className="col-4 text-end">
                            <button
                              onClick={searchCustomer}
                              className="btn btn-sm btn-primary bg-blue h-100"
                            >
                              <i className="bi bi-search me-1"></i> Search
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        {redeemCustomer === null ? (
                          <div className="text-secondary">
                            Customer Details will be appear here..
                          </div>
                        ) : redeemCustomer?.length > 0 ? (
                          <div className="mb-2">
                            <table className="table table-bordered">
                              <tbody className="table-light">
                                <tr>
                                  <th>Mobile No</th>
                                  <td> {redeemCustomer[0]?.mobile_number} </td>
                                </tr>
                                <tr>
                                  <th> Loyalty Points </th>
                                  <td>{redeemCustomer[0]?.loyalty_points}</td>
                                </tr>
                              </tbody>
                            </table>

                            <div className="text-center">
                              <button
                                title="Add Loyalty"
                                onClick={() => setAddCustomerModal(true)}
                                className="btn me-2 bg-pink btn-danger"
                              >
                                <b
                                  className="icon-center me-2"
                                  style={{ fontSize: "17px" }}
                                >
                                  +
                                </b>
                                Add Points
                              </button>
                              <a
                                onClick={() => {
                                  navigate(
                                    `/redeem/${redeemCustomer[0].id}`
                                  );
                                }}
                                title="Redeem Loyalty"
                                className="btn btn-primary bg-blue"
                              >
                                <i className="bi bi-gift-fill me-2"></i>
                                Redeem Loyalty
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            <h6
                              className="text-center p-3 text-danger"
                              style={{ backgroundColor: "#fcd9d9" }}
                            >
                              No record found !
                            </h6>

                            <p className="my-3">
                              No record found for this mobile number. <br /> do
                              you want to register?
                            </p>

                            <button
                              onClick={() => setEditCustomerModal(true)}
                              className="btn btn-danger bg-pink h-100"
                            >
                              Register Now
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="white-box">
                    <h2 className="d-flex justify-content-between align-items-center">Franchise profile

                      <ResetPassword button={{
                        name: "Reset Password",
                        type: 2,
                        email: email
                      }} />
                    </h2>

                    <div className="d-flex mt-4">
                      <div>
                        <i className="bi bi-geo-alt-fill"></i>
                      </div>
                      <div className="ms-3">
                        <strong>Address</strong>
                        <p className="paragraph mt-1">
                          <span
                            className="no-margin"
                            dangerouslySetInnerHTML={{ __html: address }}
                          ></span>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex mt-3">
                      <div>
                        <i className="bi bi-envelope-fill"></i>
                      </div>
                      <div className="ms-3">
                        <strong>E-Mail ID :</strong>
                        <p className="paragraph mt-1">{email}</p>
                      </div>
                    </div>

                    <div className="d-flex mt-3">
                      <div>
                        <i className="bi bi-telephone-fill"></i>
                      </div>
                      <div className="ms-3">
                        <strong>Phone No : </strong>
                        <p className="paragraph mt-1">{mobile}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="white-box">
                    <h2> Perfect ice cream </h2>
                    {/*  */}
                    <div className="mt-3">
                      <div className="py-1 border-bottom prefect-ice-records">
                        <p className="mb-0 py-2">Watermelon (946 ML) </p>
                        <span>$80.75</span>
                      </div>

                      <div className="py-1 border-bottom prefect-ice-records">
                        <p className="mb-0 py-2">Cotton Candy (946 ML) </p>
                        <span>$80.75</span>
                      </div>

                      <div className="py-1 border-bottom prefect-ice-records">
                        <p className="mb-0 py-2">Ferrero Rocher (3 Kg) </p>
                        <span>$115.00</span>
                      </div>

                      {/* <div className="py-1 border-bottom prefect-ice-records">
                        <p className="mb-0 py-2">Cheesecake sauce (946 ML) </p>
                        <span>$80.75</span>
                      </div>

                      <div className="py-1 border-bottom prefect-ice-records">
                        <p className="mb-0 py-2">Blue Berry (946 ML) </p>
                        <span>$80.75</span>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="white-box day-ice-cream">
                  <h2>Ice cream of the day</h2>
                  <div className="mt-3 d-flex justify-space-between">
                    <p className="mb-1">Blue Berry (946 ML) </p>
                    <h5>$80.75</h5>
                  </div>
                </div>

                {/* --------------- */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal show={show} size="lg" centered>
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Order Details</Modal.Title>
          <div className="closeModalBtn" onClick={() => setShow(false)}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Body className="bg-light p-4 pb-3">
          <div>
            {view_detail.order?.map((order, i) => {
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="  text-pink fw-bold mb-0">
                          Order ID :{" "}
                        </h6>
                        <span className="ms-3"> # {order.id}</span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="text-pink fw-bold mb-0">Email Id : </h6>
                        <span className="ms-3">{order.email}</span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <h6 className="text-pink fw-bold mb-0">Address :</h6>
                        <span
                          className="no-margin"
                          dangerouslySetInnerHTML={{ __html: order.address }}
                        ></span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div>
                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Date and Time :
                          </h6>
                          <span className="ms-3">
                            {moment(order.date_add).format(
                              "DD-MM-YYYY h:mm:ss "
                            )}
                          </span>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Payment Status :
                          </h6>
                          <span className="ms-3">{order.payment_status}</span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <h6 className=" text-pink fw-bold mb-0">
                            Order Status :{" "}
                          </h6>
                          <span className="ms-3">{order.order_status}</span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-12">
                    <div className="d-flex align-items-center mb-2">
                      <h6 className=" text-pink fw-bold mb-0">Order Note : </h6>
                      <span className="ms-3">
                        This is dummy note for the order
                      </span>
                    </div>
                  </div> */}
                    <div className="table-responsive col-12 mt-2 mb-2 fixed-table-header">
                      <table className="table table-bordered table-striped text-center">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Tax</th>
                            <th>Total</th>
                          </tr>
                        </thead>

                        <tbody>
                          {view_detail.order_items?.map((items, i) => {
                            const Total =
                              parseFloat(items.item_price).toFixed(2) *
                              parseFloat(items.item_quantity) +
                              parseFloat(items.tax_amount);
                            return (
                              <tr key={i + "item"}>
                                <td>{items.id_item}</td>
                                <td className="text-left">{items.item_name}</td>
                                <td>
                                  ${parseFloat(items.item_price).toFixed(2)}
                                </td>
                                <td>{items.item_quantity}</td>
                                <td>{items.tax_amount}</td>
                                <td>${parseFloat(Total).toFixed(2)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row bg-light">
                    <div className="col-md-6 ps-md-0 border-end">
                      <div className="white-box px-4 py-2 bg-light shadow-none">
                        <div className="row">
                          <div className="col-6 pt-2">
                            <label className="mb-2 color-pink">
                              Shipping method
                            </label>
                            <input
                              type="text"
                              value="Shipping"
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>

                          <div className="col-6 pt-2">
                            <label className="mb-2 color-pink">
                              Payment method
                            </label>
                            <input
                              type="text"
                              value="Paypal"
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>

                          <div className="col-12 pt-3">
                            <label className="mb-2 color-pink">
                              Paypal transaction status
                            </label>
                            <input
                              type="text"
                              value={order.paypal_status}
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>
                          <div className="col-12 pt-3">
                            <label className="mb-2 color-pink">
                              Paypal transaction id :
                            </label>
                            <input
                              type="text"
                              value={order.paypal_txn_id}
                              className="form-control"
                              id="0"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 pe-md-0">
                      <div className="white-box px-4 py-2 bg-light shadow-none">
                        <h2>Cart total</h2>

                        <div className="mt-3">
                          <div className="d-flex justify-content-between py-1">
                            <span>Sub total (EXCL. TAX)</span>
                            <span>
                              ${parseFloat(order.item_total).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Shipping charges</span>
                            <span>
                              ${parseFloat(order.shipping_amount).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Handling charges</span>
                            <span>
                              ${parseFloat(order.handling_charge).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Discount</span>
                            <span>
                              ${parseFloat(order.discount_amount).toFixed(2)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-1">
                            <span>Tax (HST)</span>
                            <span>
                              ${parseFloat(order.tax_amount).toFixed(2)}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between border-top text-pink mt-3 pt-3 mb-1 py-1">
                            <h4 className="fw-bold">Grand total =</h4>
                            <h4 className="fw-bold">
                              ${parseFloat(order.grand_total).toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>

      {/* Loyalty Programme */}
      {/* action Add  */}
      <Modal show={addCustomerModal} size="md" centered>
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Add Loyalty Points</Modal.Title>
          <div
            className="closeModalBtn"
            onClick={() => setAddCustomerModal(false)}
          >
            &times;
          </div>
        </Modal.Header>
        <Modal.Body className="bg-light p-4 pb-3">
          <div className="row">
            <form onSubmit={addLoyaltyPoints}>
              <div className="mb-3 col-12">
                <label htmlFor="formrow-email-Input">
                  Order ID <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 py-2"
                  id="formrow-email-Input"
                  onChange={(e) => setOrderId(e.target.value)}
                  placeholder="Enter Order ID"
                />
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="formrow-email-Input">
                  Amount <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 py-2"
                  id="formrow-email-Input"
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="mb-3 col-12">
                <label htmlFor="formrow-email-Input">
                  Loyalty Points <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 py-2"
                  id="formrow-email-Input"
                  placeholder="Enter Loyalty Points"
                  value={amount}
                  disabled
                />
              </div>

              <div className="mt-4 text-end">
                <button className="btn btn-primary bg-blue">Submit</button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {/* new Add */}
      <Modal show={editCustomerModal} size="md" centered>
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Add Loyalty Points</Modal.Title>
          <div
            className="closeModalBtn"
            onClick={() => setEditCustomerModal(false)}
          >
            &times;
          </div>
        </Modal.Header>
        <Modal.Body className="bg-light p-4 pb-3">
          <div className="row">
            <form onSubmit={addNewCustomer}>
              <div className="mb-3 col-12">
                <label htmlFor="formrow-email-Input">
                  Mobile Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 py-2"
                  id="formrow-email-Input"
                  defaultValue={mobileNo}
                  placeholder="Enter Mobile No"
                />
              </div>

              <div className="mb-3 col-12">
                <label htmlFor="formrow-email-Input">
                  Order ID <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 py-2"
                  id="formrow-email-Input"
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  placeholder="Enter Order ID"
                />
              </div>

              <div className="mb-3 col-12">
                <label htmlFor="formrow-email-Input">
                  Amount <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 py-2"
                  id="formrow-email-Input"
                  value={addAmount}
                  onChange={(e) => setAddAmount(e.target.value)}
                  placeholder="Enter Amount"
                // defaultValue={1400}
                />
              </div>

              <div className="mb-3 col-12">
                <label htmlFor="formrow-email-Input">
                  Loyalty Points <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 py-2"
                  id="formrow-email-Input"
                  placeholder="Enter Loyalty Points"
                  value={addAmount}
                  disabled
                />
              </div>

              <div className="mt-4 text-end">
                <button className="btn btn-primary bg-blue">Submit</button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Dashboard;
