import React, { useEffect } from 'react';
import Navbar from '../Home/Navbar';
import Footer from '../Home/Footer';
import { Link } from 'react-router-dom';

const ChangePassword = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <Navbar />
            <section className='pb-5 pt-4 mt-2' style={{minHeight: '77vh'}}>
                <div className="container-box">
                    <div className='top-heading'>
                        <h1>Change Password</h1>
                    </div>

                    <div className="row bg-white py-4 px-md-5 px-4 mt-4">
                        <div className="col-12">
                            <div className='mt-4 position-relative'>
                                <input type="password" placeholder='Old Password' className='input-field bg-light border' />
                                <i className="bi bi-lock input-icon" style={{left: '1.2%'}}></i>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className='mt-4 position-relative'>
                                <input type="password" placeholder='New Password' className='input-field bg-light border' />
                                <i className="bi bi-lock input-icon" style={{left: '1.2%'}}></i>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className='mt-4 position-relative'>
                                <input type="password" placeholder='Confirm New Password' className='input-field bg-light border' />
                                <i className="bi bi-lock input-icon" style={{left: '1.2%'}}></i>
                            </div>
                        </div>

                        <div className='mt-4 text-center'>
                            <Link to="/my-account" className='text-pink text-decoration-none'>Back To Your Account</Link>
                        </div>

                        <div className='mt-4 text-center'>
                            <button className='button btn-blue'>CHANGE PASSWORD</button>
                        </div>

                    </div>

                </div>
            </section>

            <Footer />
        </>
    )
}

export default ChangePassword