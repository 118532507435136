import React, { useState, useEffect } from "react";
import "../../assets/css/Training.css";
// images
import trainingImg1 from "../../assets/images/menu_inc.jpg";
import trainingImg2 from "../../assets/images/cutomer_icn.jpg";
import trainingImg3 from "../../assets/images/training_icn.jpg";
import trainingImg4 from "../../assets/images/operation_icn.jpg";
import trainingImg5 from "../../assets/images/general_icn.jpg";
import trainingImg6 from "../../assets/images/service_icn.jpg";

import feedbackImg from "../../assets/images/feedback_img.jpg";
import coronaImg from "../../assets/images/corona_icn.png";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Loader";

const Training = () => {
  const [opeIssuesModal, setOpeIssuesModal] = useState(false);
  useEffect(() => window.scrollTo(0, 0), []);

  const [related, setRelated] = useState("");
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSendIssue = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("customer_id", localStorage.getItem("vendor_id"));
    formData.append("related", related);
    formData.append("subject", subject);
    formData.append("file", file);
    formData.append("description", description);
    try {
      const response = await axios({
        method: "post",
        url: "https://api.rollzfranchise.com/api/support",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setOpeIssuesModal(false);
        setLoading(false);
        swal({
          text:
            "Operational Issues Send Successfully and Your Ticket id will be " +
            response.data.data.ticket_id,
          icon: "success",
          buttons: true,
          dangerMode: true,
        }).then(async (data) => {});
      });
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />

      <section className="pb-5 pt-4 mt-2">
        <div className="container-box">
          <div className="top-heading">
            <h1>Franchise Training</h1>
          </div>
          {/*
                    <div className='text-center my-5'>
                        <h2 className='fw-bold text-pink'>WELCOME TO <span className='text-blue'>THE TEAM</span></h2>
                        <span className='text-light-gray'>Click the icon to get started</span>
                        <div className="line mx-auto"></div>
                    </div> */}

          <div className="px-3">
            <div className="row justify-content-between bg-white rounded py-5 px-3 mt-4">
              {/* <div>
                                <h2 className='text-pink text-center fw-bold'>OTHER <span className='text-blue'>RESOURCES</span></h2>
                                <div className="line mx-auto mb-5"></div>
                            </div> */}
              <div className="col-md-7 ms-0 ">
                <div className="d-flex">
                  <img src={coronaImg} height={100} alt="" />
                  <div className="ms-4">
                    <h2 className="text-pink fw-bold">HELP US IMPROVE</h2>
                    <h5 className="text-blue">
                      Have ideas on how to make things betters? We would love to
                      hear from you!
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-1 d-md-block d-none position-relative">
                <div
                  className="middle-border shadow"
                  style={{
                    width: "1px",
                    height: "100%",
                    backgroundColor: "#f4628a",
                    position: "absolute",
                    left: "49%",
                  }}
                ></div>
              </div>
              <div className="col-md-4 mt-md-0 mt-5 text-center text-sm-start">
                <h2 className="text-pink fw-bold">EMAIL US AT: </h2>
                <h5 className="text-blue"> training@rollzicecream.ca </h5>
              </div>

              {/* ------------------------------ */}

              <div className="row justify-content-center align-items-center pe-0 mt-5">
                <div className="col-md-8 ">
                  <div className="row">
                    <div className="col-md-4 col-6">
                      <div className="text-center training-card p-2 mt-3">
                        <img src={trainingImg1} alt="" />
                        <p>Menu ideas</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="text-center training-card p-2 mt-3">
                        <img src={trainingImg2} alt="" />
                        <p>Customer Experience</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <Link
                        to="/training-program"
                        className="text-decoration-none"
                      >
                        <div className="text-center training-card p-2 mt-3">
                          <img src={trainingImg3} alt="" />
                          <p className="text-dark">Training</p>
                        </div>
                      </Link>
                    </div>
                    <div
                      className="col-md-4 col-6"
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpeIssuesModal(true)}
                    >
                      <div className="text-center training-card p-2 mt-3">
                        <img src={trainingImg4} alt="" />
                        <p>Operational issues</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="text-center training-card p-2 mt-3">
                        <img src={trainingImg5} alt="" />
                        <p>General Feedback</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="text-center training-card p-2 mt-3">
                        <img src={trainingImg6} alt="" />
                        <p>Service</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mt-md-0 mt-5">
                  <img src={feedbackImg} className="img-fluid mt-md-3" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <Modal show={opeIssuesModal} centered>
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Operational Issues</Modal.Title>
          <div
            className="closeModalBtn"
            onClick={() => setOpeIssuesModal(false)}
          >
            &times;
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSendIssue}>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label htmlFor="">Related To</label>
                <select
                  name=""
                  id=""
                  className="form-select"
                  required
                  onChange={(e) => setRelated(e.target.value)}
                >
                  <option value="">--Select--</option>
                  <option value="Machine">Machine</option>
                  <option value="Hygiene">Hygiene</option>
                </select>
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Subject"
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </div>

              <div className="col-12 mt-3">
                <label htmlFor="">Upload File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setFile(e.target.files[0])}
                  required
                />
              </div>

              <div className="col-12 mt-3">
                <label htmlFor="">Description</label>
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  placeholder="Enter Description"
                  rows="3"
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="col-12 mt-4 text-end">
                <button className="button btn-blue">Submit</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Training;
