import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import moment from "moment/moment";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Loader";
import { config } from "../../config";

const Logs = () => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("user_id", localStorage.getItem("vendor_id"));

        const res = await axios.post(`${config.apiurl}login_logs`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        setLoading(false);
        setData(res.data.data);
        console.log(res);
      } catch (error) {
        console.log(error);
        swal("", "Something Went Wrong", "error");
        setLoading(false);
      }
    }

    getData();
  }, []);

  return (
    <div>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2">
        <div className="container-box">
          <div className="top-heading">
            <h1>App Login Logs</h1>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="white-box py-4 px-md-5 px-4">
                <div>
                  {data?.length != 0 ? (
                    <ul
                      className="verti-timeline list-unstyled"
                      style={{ marginLeft: "150px" }}
                    >
                      {data.map((status, key) => {
                        return (
                          <li
                            key={key}
                            className="event-list position-relative"
                            style={{ padding: "0 0 40px 30px" }}
                          >
                            <div class="event-timeline-dot">
                              <i class="bi bi-arrow-right-circle-fill timeline-icon fs-4 "></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h5>{status.description}</h5>
                                    <p className="text-muted">
                                      At{" "}
                                      {moment(status.created_at).format(
                                        "dddd hh:mm:ss a"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <p className="timing">
                                {moment(status?.created_at).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <h5 className="text-center">No Logs has been Records</h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Logs;
