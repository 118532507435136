import React from 'react'

const Footer = () => {
  return (
    <footer>
        <p className='mb-0 fw-bold'>&copy; COPYRIGHT ROLLZ ICE CREAM 2021-2022. ALL RIGHTS RESERVED</p>
    </footer>
  )
}

export default Footer