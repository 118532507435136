import React, { useState } from 'react'
import '../../assets/css/Login.css';

// images
import logo from '../../assets/images/ice-logo.png'
import { Link } from 'react-router-dom';

const ForgotPwd1 = () => {

    // login data
    const [email, setEmail] = useState("");

    return (
        <section className='login-back'>

            <div className="login-box">
                <div className="login-form shadow">
                    <form action="#">
                        <div className='text-center'>
                            <div className='position-relative'>
                                <img src={logo} className='rollz-logo' alt="logo" /><br />
                                {/* <img src="https://ztpl.net/rollzicecream/images/best_flavour.png" className='best-flavour' alt="" />  */}
                            </div>


                            <h5 className='fw-bold text-blue pb-0 my-3'>Rollz ice cream</h5>
                            <h2 className='fw-bolder text-pink'>Forgot your password ?</h2>
                            <p className='my-2 text-blue'>No worries ! Enter your email and we will send you a reset.</p>
                        </div>

                        <div className='mt-4 position-relative'>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email Address' className='input-field bg-light border' />
                            <i className="bi bi-envelope-fill input-icon"></i>
                        </div>

                        <div className="my-3 text-center">
                            <Link to="/login" className='text-decoration-none text-pink'>Back To Login</Link>
                        </div>

                        <div className='mt-4'>
                            <button className='login-btn'>
                                <i className="bi bi-box-arrow-in-right me-1"></i> Send Request
                            </button>
                        </div>
                    </form>

                </div>
            </div>

        </section>
    )
}

export default ForgotPwd1