import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Loader from "../Loader";

const AddVendorOrder = () => {
  const [showCart, setShowCart] = useState(false);

  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showFixedCart, setShowFixedCart] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [data, setdata] = useState([]);

  const [items, setItems] = useState([]);
  const [payment_status, setPaymentStatus] = useState("paid");

  useEffect(() => {
    fetch();
    // fetch2();
  }, []);

  const [vendor_id, setVendorId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: "https://api.rollzfranchise.com/api/vendor",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async (response) => {
        setdata(response.data.data);
        setVendorId(response.data.data[0].id);
        setLoading(false);
        const formData = new FormData();
        formData.append("vendor_id", response.data.data[0].id);
        try {
          const response = await axios({
            method: "post",
            url: "https://api.rollzfranchise.com/api/vendor_customer_raw",
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then((response) => {
            const object = { qty: 1 };
            const isAdded = { isAdded: false };
            const activeData = response.data.data.filter((item) => item.is_active == 1)
            const updatedArray = activeData.map((item) => ({
              ...item,
              ...object,
              ...isAdded,
            }));

            setItems(updatedArray);
            setLoading(false);
          });
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onVendorChange = async (e) => {
    setVendorId(e.target.value);
    setLoading(true);
    const formData = new FormData();
    formData.append("vendor_id", e.target.value);
    try {
      const response = await axios({
        method: "post",
        url: "https://api.rollzfranchise.com/api/vendor_customer_raw",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        const object = { qty: 1 };
        const isAdded = { isAdded: false };
        const updatedArray = response.data.data.map((item) => ({
          ...item,
          ...object,
          ...isAdded,
        }));
        setItems(updatedArray);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  document.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setShowFixedCart(true);
    } else {
      setShowFixedCart(false);
    }
  });

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    if (cartItems.length > 0) {
      let tempTotal = 0;
      cartItems.forEach((cartItem) => {
        tempTotal += cartItem.total;
      });
      setTotalPrice(tempTotal);
    }
  }, [cartItems, products]);

  // increase quantity
  const increaseQty = (itemsId, MaxQty) => {
    setItems((prevArray) =>
      prevArray.map((obj) =>
        obj.id === itemsId && obj.qty < MaxQty
          ? { ...obj, qty: obj.qty + 1 }
          : obj
      )
    );

    setCartItems((prevArray) =>
      prevArray.map((obj) =>
        obj.id === itemsId ? { ...obj, qty: obj.qty + 1 } : obj
      )
    );
  };
  const decreaseQty = (itemsId) => {
    setItems((prevArray) =>
      prevArray.map((obj) =>
        obj.id === itemsId && obj.qty > 1 ? { ...obj, qty: obj.qty - 1 } : obj
      )
    );

    // updating cartItem quantity
    setCartItems((prevArray) =>
      prevArray.map((obj) =>
        obj.id === itemsId && obj.qty > 1 ? { ...obj, qty: obj.qty - 1 } : obj
      )
    );

    // setProducts(newData);
  };

  const handleChangeQuantity = (newQty, index) => {
    const newData = products.map((data, i) => {
      if (i === index && data.quantity >= 1) {
        data.quantity = Number(newQty);
        data.total = data.quantity * data.price;
      }
      return data;
    });

    setProducts(newData);
  };

  // cart
  const addToCart = (item, itemId) => {
    const newCartItem = {
      ...item,
      isAdded: true,
    };
    setCartItems([...cartItems, newCartItem]);

    setItems((prevArray) =>
      prevArray.map((obj) =>
        obj.id === itemId ? { ...obj, isAdded: true } : obj
      )
    );
  };

  const removeFromCart = (item, itemId) => {
    swal({
      title: "Are you sure?",
      text: "you want to remove this item",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setItems((prevArray) =>
          prevArray.map((obj) =>
            obj.id === itemId ? { ...obj, isAdded: false } : obj
          )
        );

        setCartItems(cartItems.filter((cartItem) => cartItem.id !== itemId));
      }
    });
  };

  const openCart = () => {
    setShowCart(true);
  };

  const [order_note, setOrderNote] = useState("");
  const [order_status, setOrderStatus] = useState("");

  const createOrder = (e) => {
    const item = cartItems.map((item) => {
      return item.id;
    });
    const Qty = cartItems.map((item) => {
      return item.qty;
    });

    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Do you really want to place the order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setLoading(true);
        const formData = new FormData();
        formData.append("vendor_id", vendor_id);
        formData.append("customer_id", localStorage.getItem("vendor_id"));
        formData.append("payment_status", payment_status);
        formData.append("order_status", order_status);
        formData.append("order_note", order_note);
        formData.append("items", item);
        formData.append("qty", Qty);
        try {
          const response = await axios({
            method: "post",
            url: "https://api.rollzfranchise.com/api/create_vendor_order",
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then((response) => {
            swal({
              icon: "success",
              text: "Order Created Successfully",
            }).then((data) => {
              setShowCart(false);
              setCartItems([]);
              navigate("/rollz-mix");
            });
            setLoading(false);
          });
        } catch (err) {
          swal({
            icon: "error",
            text: "SomeThing Went Wrong",
          });
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2">
        <div className="container-box">
          <div className="top-heading">
            <h1>Add Vendor Order</h1>
          </div>
          <div
            className={`cart-btn fixed-cart-btn text-center ${
              showFixedCart ? "d-flex" : "d-none"
            }`}
            onClick={() => setShowCart(true)}
          >
            <i className="bi bi-cart4"></i>
            <span className="cartCountBtn shadow">{cartItems.length}</span>
          </div>

          <div className="row mt-2 justify-content-center">
            <div className="col-12">
              <div className="white-box p-4">
                <form action="" onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-4 mb-4">
                      <label htmlFor="" className="mb-1">
                        Vendor <span className="text-danger">*</span>
                      </label>
                      <select
                        name=""
                        id=""
                        className="form-select rounded-0 py-2"
                        onChange={onVendorChange}
                      >
                        {data.map((item, i) => {
                          return (
                            <option value={item.id} key={i}>
                              {item.first_name + " " + item.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-md-8 d-flex align-items-start justify-content-end ">
                      <div className="cart-btn d-flex" onClick={openCart}>
                        <i className="bi bi-cart4"></i>
                        <span className="px-2">Cart</span>
                        <span className="cartCountBtn shadow">
                          {cartItems.length}
                        </span>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-bordered text-center mobile-table">
                          <thead className="table-danger">
                            <tr>
                              <th className="text-start"> Item name</th>
                              <th className="col-md-2">Max Quantity</th>
                              <th className="col-md-2">Quantity</th>

                              <th className="col-md-2">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {items.map((product, i) => {
                              let MaxQty;
                              if (product.max_qty == 0) {
                                MaxQty = "As Much You Want";
                              } else {
                                MaxQty = product.max_qty;
                              }
                              return (
                                <tr key={i} className="bg-info">
                                  <td className="text-start">{product.name}</td>
                                  <td>{MaxQty}</td>
                                  <td>
                                    <button
                                      className="py-1 px-3 border-pink btn-light-pink"
                                      onClick={() => decreaseQty(product.id)}
                                    >
                                      –
                                    </button>

                                    <input
                                      type="number"
                                      value={product.qty}
                                      className="py-1 border-pink text-center"
                                      style={{ width: "46px", outline: "none" }}
                                      onChange={(ev) =>
                                        handleChangeQuantity(ev.target.value, i)
                                      }
                                    />

                                    <button
                                      className="py-1 px-3 border-pink btn-light-pink"
                                      onClick={() =>
                                        increaseQty(product.id, product.max_qty)
                                      }
                                    >
                                      +
                                    </button>
                                  </td>
                                  <td>
                                    {product.isAdded ? (
                                      <button
                                        className="add-to-cart bg-danger"
                                        onClick={() =>
                                          removeFromCart(product, product.id)
                                        }
                                      >
                                        {" "}
                                        <i className="bi bi-trash-fill"></i>{" "}
                                        Remove
                                      </button>
                                    ) : (
                                      <button
                                        className="add-to-cart"
                                        onClick={() =>
                                          addToCart(product, product.id)
                                        }
                                      >
                                        Add to Cart
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <Modal
        show={showCart}
        size="xl"
        centered
        onHide={() => setShowCart(false)}
      >
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Shopping Cart</Modal.Title>
          <div className="closeModalBtn" onClick={() => setShowCart(false)}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={createOrder}>
            <div className="table-responsive">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Product Name</th>
                    <th>Max Quantity</th>
                    <th>Quantity</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {cartItems.map((cartItem, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <div className="py-2">{i + 1}</div>
                        </td>
                        <td>{cartItem.name}</td>
                        <td>{cartItem.max_qty}</td>
                        <td>
                          <a
                            className="py-1 px-3 border-pink btn-light-pink"
                            onClick={() => decreaseQty(cartItem.id)}
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            –
                          </a>

                          <input
                            type="number"
                            value={cartItem.qty}
                            className="py-1 border-pink text-center"
                            style={{ width: "46px", outline: "none" }}
                            onChange={(ev) =>
                              handleChangeQuantity(ev.target.value, i)
                            }
                          />

                          <a
                            className="py-1 px-3 border-pink btn-light-pink"
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => increaseQty(cartItem.id)}
                          >
                            +
                          </a>
                        </td>

                        <td>
                          <i
                            className="bi bi-trash-fill fs-4 text-danger"
                            onClick={() =>
                              removeFromCart(cartItem, cartItem.id)
                            }
                            style={{ cursor: "pointer" }}
                            title="Remove This Item"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p className="text-center text-danger fw-bold">
                {cartItems.length > 0 ? null : " Cart is empty ! "}
              </p>
            </div>

            <div className="row mt-4 bg-light p-4">
              <div className="col-md-12 ps-md-0 ">
                <div className="white-box px-4 py-2 bg-light shadow-none">
                  <h2>Shipping method</h2>

                  <div className="row">
                    <div className="col-6 mt-4">
                      <label htmlFor="" className="mb-1">
                        Order Status
                      </label>
                      <select
                        name=""
                        id=""
                        className="form-select rounded-0 py-2"
                        onChange={(e) => setOrderStatus(e.target.value)}
                      >
                        <option value="">--Select Status</option>
                        <option value="Processing">Processing</option>
                        <option value="pending">Pending</option>
                        <option value="completed">Completed</option>
                        <option value="Shipped">Shipped</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    </div>
                    <div className="col-6 mt-4">
                      <label htmlFor="" className="mb-1">
                        Payment Status
                      </label>
                      <select
                        name=""
                        id=""
                        className="form-select rounded-0 py-2"
                        onChange={(e) => setPaymentStatus(e.target.value)}
                        value={payment_status}
                      >
                        <option value="">--Select Status</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">UnPaid</option>
                      </select>
                    </div>

                    <div className="col-12 mt-4">
                      <label htmlFor="" className="mb-1">
                        Order Note
                      </label>
                      <textarea
                        placeholder="Add Note"
                        rows="3"
                        style={{ resize: "none" }}
                        className="form-control rounded-0 py-2"
                        onChange={(e) => setOrderNote(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="mt-3">
                      <div className="my-2">
                        <button className="button place-order-btn fw-bold w-100 btn-blue">
                          <i className="bi bi-check2-circle me-1 icon-center"></i>{" "}
                          PLACE AN ORDER
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddVendorOrder;
