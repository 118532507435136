import React, { useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import "../../assets/css/Dashboard.css";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/ice-logo.png";
import UserProfile from "../../assets/images/user-profile.jpg";

const Navbar = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);

  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      buttons: true,
      title: "Are you sure ?",
      text: "You want to Logout",
    }).then((willDelete) => {
      if (willDelete) {
        localStorage.removeItem("vendor_id");
        localStorage.removeItem("auth_name");
        navigate("/login");
      }
    });
  };

  const Name =
    localStorage.getItem("auth_name")?.split(" ")[0] +
    " " +
    localStorage.getItem("auth_name")?.split(" ")[1].slice(0, 1);

  return (
    <>
      <header className="header_bg nav2">
        <div className="container-box navibar">
          <ul
            className={`navlinks ps-0 ${showMobileNav ? "showMobileNav" : ""}`}
          >
            <li>
              <NavLink to="/">Dashboard </NavLink>
            </li>
            <li>
              <NavLink to="/ordering" title="Order">
                {" "}
                Ordering
              </NavLink>
            </li>
            <li>
              <NavLink to="/order-history" title="Order History">
                {" "}
                History
              </NavLink>
            </li>
            <li>
              <NavLink to="/training" title="Franchise Training">
                {" "}
                Training
              </NavLink>
            </li>
            <li>
              <NavLink to="/rollz-mix">Rollz Mix</NavLink>
            </li>

            <li className="d-md-none d-block">
              <NavLink to="/offers">Offers</NavLink>
            </li>

            <li className="d-md-none d-block">
              <NavLink to="/credential">App Credential</NavLink>
            </li>
          </ul>
          <div className={`middle-img`}>
            <Link to="/">
              <img src={Logo} alt="Logo" className="ice-logo" />
            </Link>
          </div>

          <div className="right-nav d-flex justify-content-between align-items-start">
            <div className="menu-btn">
              <i
                className={`bi bi-${showMobileNav ? "x" : "list"} text-white`}
                style={{ fontSize: "40px" }}
                onClick={() => setShowMobileNav(!showMobileNav)}
              ></i>
            </div>
            <div className="d-flex align-items-center">
              <ul className={`navlinks ps-0 me-lg-4 mt-1`}>
                <li>
                  <NavLink to="/credential">App Credential</NavLink>
                </li>
                <li>
                  <NavLink to="/logs">Login Logs</NavLink>
                </li>
              </ul>

              <div className="message-icon-heart position-relative  ">
                <img
                  src={UserProfile}
                  className="profile_img "
                  style={{ width: "35px" }}
                  alt=""
                />
                <span
                  className="user_nm ps-1"
                  style={{ textTransform: "capitalize" }}
                >
                  Hi, {Name}
                </span>

                <div className="ps-cart__content ">
                  <ul className="ps-list--arrow">
                    <li className="ps-block__footer border-top-none">
                      <Link to={`/my-account`}>Profile</Link>
                    </li>
                    <li className="ps-block__footer border-top-none">
                      <a style={{ cursor: "pointer" }} onClick={handleLogout}>
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <div className="log_out">
                  <a href="" onClick={handleLogout}>
                    <i className="bi bi-power"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
