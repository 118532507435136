import { Modal } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert';
import axios from 'axios';
import Loader from '../Loader';

const ResetPassword = ({ button }) => {

    const [modal, setModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState(button.email);
    const [password, setPassword] = useState("");

    useEffect(() => {
        setEmail(button.email)
    }, [button.email])

    useEffect(() => {
        setName(button.uname)
        setPassword(button.password)
    }, [button])

    // dashboard
    const onResetPassword = async () => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("password", password)
            formData.append("email", email)
            formData.append("user_id", localStorage.getItem("vendor_id"))

            const res = await axios.post("https://api.rollzfranchise.com/api/reset_password_vendor", formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                }
            })
            setLoading(false)
            console.log("res", res)
            setModal(false)
            swal("", "Password Reset Successfully", "success")

        } catch (error) {
            console.log(error)
            swal("", "Something Went Wrong", "error")
            setLoading(false)
        }
    }

    // credential
    const onResetProfile = async () => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("username", name)
            // formData.append("email", email)
            formData.append("password", password)
            formData.append("user_id", localStorage.getItem("vendor_id"))

            const res = await axios.post("https://api.rollzfranchise.com/api/vendor_reset_password", formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                }
            })
            setLoading(false)

            setModal(false)
            swal("", "Password Reset Successfully", "success")

            setName("")
            setPassword("")

        } catch (error) {
            console.log(error)
            swal("", "Something Went Wrong", "error")
            setLoading(false)
        }
    }


    const onSubmitForm = (e) => {
        e.preventDefault()
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to change the details.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                if (button.type === 1)
                    onResetProfile()
                else
                    onResetPassword()
            }
        })
    }


    return (
        <>
            {
                isLoading && <Loader />
            }
            <button className='button btn-blue' onClick={() => setModal(true)}>
                {button.name}
            </button>


            <Modal show={modal} centered>
                <Modal.Header className="bg-blue text-white">
                    <Modal.Title className="fw-bold">{button.name}</Modal.Title>
                    <div
                        className="closeModalBtn"
                        onClick={() => setModal(false)}
                    >
                        &times;
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={onSubmitForm}>
                        <div className="row">

                            {
                                button.type === 1 ?
                                    <div className="col-12 mt-3">
                                        <label className='mb-1'>User Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter User Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div> :
                                    <div className="col-12 mt-3">
                                        <label className='mb-1'>Email Address</label>
                                        <div className='border py-2 rounded px-3 lh-sm'>
                                            {email}
                                        </div>
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Email Address"
                                            value={email}
                                            readOnly
                                            onChange={(e) => setEmail(e.target.value)}
                                        /> */}
                                    </div>


                            }


                            <div className="col-12 mt-3">
                                <label className='mb-1'>Password</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="col-12 mt-4 text-end">
                                <button className="button btn-blue">Submit</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ResetPassword