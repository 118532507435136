import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router";
import axios from "axios";
import Loader from "../Loader";

const RedeemIndex = () => {
  const [showCart, setShowCart] = useState(false);

  const categoriesData = [
    {
      catagoryName: "Rolled Ice Cream",
      data: [
        {
          id: 1,
          product_id: 15,
          item_name: "Ferrero Rocher",
          price: 115.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 2,
          product_id: 32,
          item_name: "Tiramisu",
          price: 80.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 3,
          product_id: 0,
          item_name: "Strawberry Cheesecake",
          price: 120.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 4,
          product_id: 35,
          item_name: "Pistachio",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 5,
          product_id: 0,
          item_name: "Rose & Pistachio",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 6,
          product_id: 0,
          item_name: "Sapodilla (Chikoo)",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 7,
          product_id: 38,
          item_name: "Maharaja Paan",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 8,
          product_id: 0,
          item_name: "Kinder Bueno",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 9,
          product_id: 0,
          item_name: "Strawberry Shortcake",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 10,
          product_id: 37,
          item_name: "Fig & Almond",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 11,
          product_id: 0,
          item_name: "Red Velvet",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 12,
          product_id: 0,
          item_name: "Biscoff Salted Caramel",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 13,
          product_id: 0,
          item_name: "Nutella Crunch",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 14,
          product_id: 0,
          item_name: "Caramel Kitkat",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
        {
          id: 15,
          product_id: 0,
          item_name: "Netflix",
          price: 62.0,
          quantity: 1,
          points: 100,
          total: 100,
        },
      ],
    },
    {
      catagoryName: "Milkshake",
      data: [
        {
          id: 16,
          product_id: 0,
          item_name: "Oreolicious",
          price: 115.0,
          quantity: 1,
          points: 90,
          total: 90,
        },
        {
          id: 17,
          product_id: 0,
          item_name: "Berrylicious",
          price: 115.0,
          quantity: 1,
          points: 90,
          total: 90,
        },
        {
          id: 18,
          product_id: 0,
          item_name: "Nuts-i-wanna",
          price: 80.0,
          quantity: 1,
          points: 90,
          total: 90,
        },
        {
          id: 19,
          product_id: 0,
          item_name: "mmm Mango",
          price: 120.0,
          quantity: 1,
          points: 90,
          total: 90,
        },
        {
          id: 20,
          product_id: 0,
          item_name: "Kesar Mango",
          price: 120.0,
          quantity: 1,
          points: 90,
          total: 90,
        },
        {
          id: 21,
          product_id: 0,
          item_name: "Earl Grey Biscoff Milkshake",
          price: 120.0,
          quantity: 1,
          points: 90,
          total: 90,
        },
        {
          id: 22,
          product_id: 0,
          item_name: "Matcha Marble Milkshake",
          price: 120.0,
          quantity: 1,
          points: 90,
          total: 90,
        },
      ],
    },
    {
      catagoryName: "Falooda",
      data: [
        {
          id: 23,
          product_id: 0,
          item_name: "Rose & Strawberry",
          price: 115.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
        {
          id: 24,
          product_id: 0,
          item_name: "Kesar & Mango",
          price: 120.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
        {
          id: 25,
          product_id: 0,
          item_name: "Rabdi Falooda",
          price: 80.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
        {
          id: 26,
          product_id: 0,
          item_name: "Kesar Pista",
          price: 120.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
      ],
    },
    {
      catagoryName: "Poping Soda",
      data: [
        {
          id: 27,
          product_id: 0,
          item_name: "Lychee Twister",
          price: 115.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
        {
          id: 28,
          product_id: 0,
          item_name: "Hawaiian Hurricane",
          price: 80.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
        {
          id: 29,
          product_id: 0,
          item_name: "Passion Mango",
          price: 120.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
        {
          id: 30,
          product_id: 0,
          item_name: "Pineapple & Mint",
          price: 115.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
        {
          id: 31,
          product_id: 0,
          item_name: "Mango Mojito",
          price: 80.0,
          quantity: 1,
          points: 80,
          total: 80.0,
        },
        {
          id: 32,
          product_id: 0,
          item_name: "Kalakhata",
          price: 120.0,
          quantity: 1,
          points: 80,
          total: 80,
        },
      ],
    },
  ];

  const [cartItems, setCartItems] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loyaltyPoints, setLoyaltyPoints] = useState();
  const [total, setTotal] = useState(0);

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    let tot = 0;
    cartItems.forEach(
      (item) =>
        (tot += parseFloat(item.loyalty_points) * parseFloat(item.quantity))
    );
    setTotal(tot);
  }, [cartItems]);

  // for cart items
  const increaseQuantity = (itemId) => {
    if (cartItems.find((item) => item.id === itemId)) {
      const index = cartItems.findIndex((item) => item.id === itemId);

      if (loyaltyPoints >= cartItems[index].loyalty_points) {
        const updatedCartItems = cartItems.map((item) =>
          item.id === itemId
            ? {
                ...item,
                quantity: item.quantity + 1,
                total: item.loyalty_points * (item.quantity + 1),
              }
            : item
        );

        setCartItems(updatedCartItems);
        setLoyaltyPoints((prev) => prev - cartItems[index].loyalty_points);
      } else {
        swal("You don't have enough loyalty points", "", "warning");
        return;
      }
    }

    const updatedCartItems = categoryData.map((item) =>
      item.id === itemId
        ? {
            ...item,
            quantity: item.quantity + 1,
            total: item.points * (item.quantity + 1),
          }
        : item
    );
    setCategoryData(updatedCartItems);
  };

  const decreaseQuantity = (itemId) => {
    if (cartItems.find((item) => item.id === itemId)) {
      const updatedCartItems = cartItems.map((item) =>
        item.id === itemId && item.quantity > 1
          ? {
              ...item,
              quantity: item.quantity - 1,
              total: item.points * (item.quantity - 1),
            }
          : item
      );
      setCartItems(updatedCartItems);

      const index = cartItems.findIndex((item) => item.id === itemId);
      if (cartItems[index].quantity > 1) {
        setLoyaltyPoints((prev) => prev + cartItems[index].loyalty_points);
      }
    }

    const updatedCartItems = categoryData.map((item) =>
      item.id === itemId && item.quantity > 1
        ? {
            ...item,
            quantity: item.quantity - 1,
            total: item.points * (item.quantity - 1),
          }
        : item
    );

    setCategoryData(updatedCartItems);
  };

  const addToCart = (item) => {
    console.log(item);
    if (loyaltyPoints >= item.loyalty_points) {
      setCartItems((prev) => [...prev, item]);
      setLoyaltyPoints(
        (prev) =>
          prev - parseFloat(item.loyalty_points) * parseFloat(item.quantity)
      );
    } else {
      swal("you don't have enough loyalty points", "", "warning");
    }
  };

  const removeCartItem = (id) => {
    swal({
      title: "Are you sure?",
      text: "You really want to remove this item form cart",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setCartItems(cartItems.filter((item) => item.id !== id));
        setLoyaltyPoints(
          (prev) =>
            prev +
            parseFloat(
              cartItems[cartItems.findIndex((item) => item.id === id)]
                .loyalty_points
            ) *
              parseFloat(
                cartItems[cartItems.findIndex((item) => item.id === id)]
                  .quantity
              )
        );
      }
    });
  };

  const onChangeCategory = (e) => {
    const cateName = e.target.value;

    const selectedCategory = categoriesData.filter(
      (data) => data.catagoryName === cateName
    );
    setCategoryData(selectedCategory[0]);
  };

  const params = useParams();

  useEffect(() => {
    onCustomerData();
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [customer_id, setCustomerId] = useState("");

  const onCustomerData = async (e) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.rollzfranchise.com/api/show_customer/${params.id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCustomerId(response.data.data?.id);
      setLoyaltyPoints(response.data.data?.loyalty_points);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      swal("Something Went Wrong", "", "error");
    }
  };

  const [order_id, setOrderId] = useState("");

  const onRedeemPoints = (e) => {
    swal({
      title: "Are you sure?",
      text: "You really want to Redeem Points",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const ids = cartItems.map((item) => item.id);
        const names = cartItems.map((item) => item.menu_item_name);
        const product_points = cartItems.map((item) => item.loyalty_points)
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append("customer_id", customer_id);
          formData.append("user_id", localStorage.getItem("vendor_id"));
          formData.append("order_id", order_id);
          formData.append("loyalty_points", total);
          formData.append("location", localStorage.getItem("vendor_id"));
          formData.append("product_id", ids);
          formData.append("product_points", product_points);
          formData.append("product_name", names);

          const response = await axios.post(
            "https://api.rollzfranchise.com/api/redeem_product",
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setLoading(false);
          swal("", "Points Redeemed Successfully", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          console.log(error);
          setLoading(false);
          swal("Something Went Wrong", "", "error");
        }
      }
    });
  };

  const [data, setdata] = useState([]);
  const [items, setItmes] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: "https://api.rollzfranchise.com/api/menu_category",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async (response) => {
        const datas = response.data.data.filter(obj => {
          if (obj.is_active == 1) {
            return obj
          }
        })
        setdata(datas)

        const res = await axios({
          method: "get",
          url: "https://api.rollzfranchise.com/api/menu_items",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((res1) => {
          const object = { quantity: 1 };
          const updatedArray = res1.data.data.map((item) => ({
            ...item,
            ...object,
          }));
          const datas = updatedArray.filter(obj => {
            if (obj.is_active == 1) {
              return obj
            }
          })
          setCategoryData(datas);
          setLoading(false);
        });

        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <section className="pb-5 pt-4 mt-2">
        <div className="container-box">
          <div className="top-heading">
            <h1>Redeem Loyalty</h1>
          </div>

          <div className="row mt-4 px-3">
            <div className="">
              <div className="d-flex mb-3 " style={{ justifyContent: "end" }}>
                <div className="cart-btn  d-flex me-2">
                  <span className="pe-3">Loyalty Points</span>
                  <span className="cartCountBtn shadow">{loyaltyPoints}</span>
                </div>
                <div
                  className="cart-btn d-flex"
                  onClick={() => setShowCart(true)}
                >
                  <i className="bi bi-cart4"></i>
                  <span className="px-2">Redeem</span>
                  <span className="cartCountBtn shadow">
                    {cartItems.length}
                  </span>
                </div>
              </div>

              {data.map((category, index) => {
                return (
                  <div key={index} className="mb-4">
                    <div
                      className="accordion"
                      id="accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-item bg-white">
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button ${
                              index === 0 ? "" : "collapsed"
                            } py-4`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#panelsStayOpen-collapseOne${index}`}
                            aria-expanded="true"
                            aria-controls={`panelsStayOpen-collapseOne${index}`}
                          >
                            <h5 className="mb-0 fw-bold text-pink">
                              {category.menu_name}
                            </h5>
                          </button>
                        </h2>
                        <div
                          id={`panelsStayOpen-collapseOne${index}`}
                          className={`accordion-collapse collapse ${
                            index === 0 ? "show" : ""
                          }`}
                        >
                          <div className="accordion-body">
                            <div className="row pt-3">
                              <div className="col-12">
                                <div className="table-responsive">
                                  <table className="table table-bordered text-center mobile-table">
                                    <thead className="table-danger">
                                      <tr>
                                        <th className="text-start ">
                                          Item name
                                        </th>
                                        <th className="col-3">Quantity</th>
                                        <th className="col-2">Points</th>
                                        <th className="col-md-2">Action</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {categoryData.map((data, i) => {
                                        if (category.id == data.menu_id) {
                                          return (
                                            <tr key={i} className="bg-info">
                                              <td className="text-start">
                                                {data.menu_item_name}
                                              </td>
                                              <td>
                                                <button
                                                  className="py-1 px-3 border-pink btn-light-pink"
                                                  onClick={() =>
                                                    decreaseQuantity(data.id)
                                                  }
                                                >
                                                  –
                                                </button>

                                                <input
                                                  type="number"
                                                  value={data.quantity}
                                                  disabled
                                                  className="py-1 border-pink text-center"
                                                  style={{
                                                    width: "46px",
                                                    outline: "none",
                                                  }}
                                                />

                                                <button
                                                  className="py-1 px-3 border-pink btn-light-pink"
                                                  onClick={() =>
                                                    increaseQuantity(data.id)
                                                  }
                                                >
                                                  +
                                                </button>
                                              </td>
                                              <td className="fw-bold">
                                                {data.loyalty_points}
                                              </td>
                                              <td>
                                                {cartItems.find(
                                                  (item) => item.id === data.id
                                                ) ? (
                                                  <button
                                                    onClick={() =>
                                                      removeCartItem(data.id)
                                                    }
                                                    className="add-to-cart bg-danger"
                                                  >
                                                    <i className="bi bi-trash-fill"></i>{" "}
                                                    Remove
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      addToCart(data)
                                                    }
                                                    className="add-to-cart"
                                                  >
                                                    Add to Cart
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <Modal
        show={showCart}
        size="xl"
        centered
        onHide={() => setShowCart(false)}
      >
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Shopping Cart</Modal.Title>
          <div className="closeModalBtn" onClick={() => setShowCart(false)}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <form>
            <div className="table-responsive">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Points</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {cartItems.map((cartItem, i) => {
                    const SubTotal =
                      parseFloat(cartItem.loyalty_points) *
                      parseFloat(cartItem.qty);

                    const Percent =
                      SubTotal * (parseFloat(cartItem.percentage) / 100);

                    const Total = SubTotal + Percent;

                    return (
                      <tr key={i}>
                        <td>
                          <div className="py-2">{i + 1}</div>
                        </td>
                        <td>{cartItem.menu_item_name} </td>
                        <td>
                          <a
                            className="py-1 px-3 border-pink btn-light-pink"
                            onClick={() => decreaseQuantity(cartItem.id)}
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            –
                          </a>

                          <input
                            type="number"
                            value={cartItem.quantity}
                            className="py-1 border-pink text-center"
                            style={{ width: "46px", outline: "none" }}
                            disabled
                          />

                          <a
                            className="py-1 px-3 border-pink btn-light-pink"
                            onClick={() => increaseQuantity(cartItem.id)}
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            +
                          </a>
                        </td>
                        <th>{cartItem.loyalty_points}</th>
                        <td>
                          <i
                            className="bi bi-trash-fill fs-4 text-danger"
                            onClick={() => removeCartItem(cartItem.id)}
                            style={{ cursor: "pointer" }}
                            title="Remove This Item"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p className="text-center text-danger fw-bold">
                {cartItems.length > 0 ? null : "Oops! Cart is empty "}
              </p>
            </div>
          </form>

          <div className="row justify-content-end p-4 bg-light">
            <div className="col-md-6">
              <div className="mb-3 ">
                <label htmlFor="formrow-email-Input">
                  Order ID <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 py-2 mt-2"
                  id="formrow-email-Input"
                  onChange={(e) => setOrderId(e.target.value)}
                  placeholder="Enter Order ID"
                />
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="white-box px-4 py-2 bg-light shadow-none">
                <div className="d-flex justify-content-between text-pink mt-2">
                  <h4 className="fw-bold mb-0 mt-1">Grand total = </h4>
                  <h4 className="fw-bold mb-0 mt-1">{total.toFixed(2)}</h4>
                </div>
              </div>
            </div>

            <div className="text-center mt-3">
              <button
                className="button bg-pink"
                onClick={() => onRedeemPoints()}
              >
                <i className="bi bi-gift me-2"></i>
                Redeem
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RedeemIndex;
